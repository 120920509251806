import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import QrScanner from 'qr-scanner';
import { toast } from 'react-toastify';

import { FileUpload } from 'components/functional/FileUpload/FileUpload';
import { useJoinVibeSession } from 'services/vibe_check/vibe.query';
import { getUserData } from 'utils/utils';
import QrcodeDecoder from 'qrcode-decoder';

const ScannerComponent: React.FC = () => {
  // QR States
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const { user_id } = getUserData();
  const isScaning = useRef<boolean>();

  const { mutate: joinVibeSession, mutateAsync: joinVibe, isPending } = useJoinVibeSession();

  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedList = state?.selectedList;

  // Success
  const onScanSuccess = async (image: QrScanner.ScanResult) => {
    if (!isScaning.current) {
      isScaning.current = true;
      // joinVibeSession({ code: result.toString(), user_id, list_id: selectedList });
      joinVibe({ code: image.data, user_id, list_id: selectedList }).catch((error) => {
        isScaning.current = false;
      });
    }
  };

  useEffect(() => {
    if (!selectedList) {
      navigate('/home/join-vibe-check');
    }
  }, []);

  useEffect(() => {
    let currentVideoEl = videoEl.current;
    if (currentVideoEl && !scanner.current) {
      // 👉 Instantiate the QR Scanner

      scanner.current = new QrScanner(currentVideoEl, onScanSuccess, {
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: 'environment',
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      console.log(currentVideoEl, 'sideEffect');
      if (!videoEl.current) {
        scanner?.current?.stop();
        console.log(currentVideoEl, 'sideEffect within');
      }
    };
  }, []);

  if (!selectedList) {
    return <></>;
  }

  return (
    <div className="qr-reader md:m-auto max-w-[800px] ">
      {isPending && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-75 z-50">
          <PuffLoader color="black" className=" m-auto" />
        </div>
      )}
      {
        qrOn ? (
          <video ref={videoEl}></video>
        ) : (
          <div>
            <h1 className="text-center text-xl">Please give access to camera for scanning QR</h1>
          </div>
        )
        // <div className=' min-h-[88lvh] p-8 bg-[#F7F6F9]'>
        //   <div role="alert">
        //     <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
        //       Unable to Open Camera
        //     </div>
        //     <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-1 text-red-700 text-[13px]">
        //       <p>Camera is blocked or not accessible. Please allow camera permissions and Reload.</p>
        //     </div>
        //   </div>
        //   <div className="max-w-md rounded shadow-lg mt-3 mx-auto">
        //     <div className="px-6 py-2 flex flex-col gap-3">
        //       <div className="font-bold text-xl">Scan QR code from Image</div>
        //       <p className="text-gray-700 text-base">
        //         You can also upload an image of a QR code to join Vibe Check
        //       </p>
        //       <FileUpload onUpload={scanImage} />
        //     </div>
        //   </div>
        // </div>
      }
    </div>
  );
};
export default ScannerComponent;
