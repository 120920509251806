import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import SuccessIcon from 'assets/Success.svg';

const SignupConfirmation = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-2 items-center  mt-14 px-4 max-w-[600px] mx-auto">
            <h1 className="text-2xl font-bold">Sign Up</h1>
      <img src={SuccessIcon} alt="SuccessIcon" />
      <h1 className="text-2xl font-bold">Congratulations!</h1>
      <p className=' text-center'>
        You have successfully created your account. An activation link has been sent to your email address.Please check your inbox (and spam folder, just in case) and click on the link to activate
        your account. Once your account is activated, you can <Link to="/login">log in</Link> and start using our services.
      </p>
      <button className=" bg-vibe_blue py-4 mt-3  text-white text-1xl font-semibold rounded-lg w-full max-w-[368px] flex justify-center items-center gap-2" onClick={() => navigate('/')}>
        Countinue
      </button>
    </div>
  );
};

export default SignupConfirmation;
