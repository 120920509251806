import React, { ChangeEvent } from 'react';

interface InputFieldProps {
    label: string;
    type: string;
    name: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    placeholder?: string;
    editable: boolean;
    containerClassName?: string;
    labelClassName?: string;
    width?: string;
}

const InputField: React.FC<InputFieldProps> = ({
    label,
    type,
    name,
    value,
    onChange,
    required = false,
    placeholder = '',
    editable,
    containerClassName,
    labelClassName,
    width,
}) => {
    return (
        <div className={`form-group ${containerClassName ?? 'flex flex-col md:flex-row items-center w-full'}`}>
            <label className={`${labelClassName ?? 'md:w-36 lg:w-56 font-bold text-[#195CE5]'}`}>
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <input
                className={`border border-[#195CE5] rounded-md p-2 h-8  focus:outline-[#5f84d0] ${width ?? 'md:max-w-56 flex-1 w-full'}`}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                placeholder={placeholder}
                readOnly={!editable}
            />
        </div>
    );
};

export default InputField;
