import { StylesConfig } from 'react-select';
import { MdEdit } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { FaCirclePlay } from 'react-icons/fa6';
import { FaCirclePause } from 'react-icons/fa6';

export const imgLink = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIZ2XKQppqMkYTkcL_TE4pIYvQb_yS0tphX7WzJJBtVQ&s';

export const NO_HOC_ROUTES = [
  '/login',
  '/sign-up',
  '/sign-up/confirmation',
  '/forgot-password',
  '/reset-password-verification',
  '/verify-email',
  // '/profile/edit',
  // '/profile/edit/add-interest',
];

export const ONLY_LOGO_NAVBAR_ROUTES = ['/activate-account', '/verify-email', '/users', '/view-user'];
export const customStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#e5e7eb' : '#e5e7eb', // Change border color on focus
    height: 56, // Add box shadow on focus
    '&:focus': {
      borderColor: '#6c757d', // Change border color on hover
    },
    outline: 'none',
    ringColor: 'transparent',
  }),
};

export const CELL_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  DATE: 'DATE',
  LIST: 'LIST',
  BUTTONS: 'BUTTONS',
  CHECKBOX: 'CHECKBOX',
  COLORFUL_TEXT: 'COLORFUL_TEXT',
  DROPDOWN: 'DROPDOWN',
} as const;

export const BUTTON_TYPES = {
  VIEW: {
    icon: <IoEye color="#195CE5" size={'25px'} />,
  },
  EDIT: {
    icon: <MdEdit color="#007AFE" size={'25px'} />,
  },
  DELETE: {
    icon: <MdDelete color="red" size={'25px'} />,
  },
  PLAY: {
    icon: <FaCirclePlay color="#4CD964" size={'25px'} />,
  },
  PAUSE: {
    icon: <FaCirclePause color="#FFCC00" size={'25px'} />,
  },
};

export const leadStatusDict: Record<string, string> = {
  awaiting_reply: 'Awaiting reply',
  in_conversation: 'In Conversation',
  interested: 'Interested',
  not_interested: 'Not Interested',
};

export const admin_email = 'aliaamir2015@gmail.com';
