import { useNavigate } from 'react-router-dom';
import { useState, ChangeEvent, useEffect } from 'react';
import { MdFilterAlt } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';

import InputField from 'components/InputField/InputField';
import DropdownField from 'components/DropdownField/DropdownField';
import { useSearchParams } from 'react-router-dom';

import { ITableColumns } from 'types/common';

interface IFiltersState {
  search: string;
  filterBy: string;
}

const Filters: React.FC<{ columns: ITableColumns[]; identifier?: string }> = ({ columns, identifier }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const labels = columns?.filter((col) => !!col.label.trim() && col.label !== 'Image').map((col) => col.label);
  const selected = columns?.filter((col) => searchParams.get(`${identifier}filterBy`) === col.key);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(`${identifier}filterBy`);
    if (!selected) {
      navigate(`?${newSearchParams.toString()}`);
    }
  }, []);
  const navigate = useNavigate();

  const [filters, setFilter] = useState<IFiltersState>({
    search: searchParams.get(`${identifier}search`) || '',
    filterBy: selected[0]?.label || labels[0] || '',
  });

  const handleFilter = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    Object.entries(filters).forEach(([key, value]) => {
      if (value.trim()) {
        if (key === 'filterBy') {
          const filteredCol = columns.find((col) => col.label === value);
          if (filteredCol) {
            newSearchParams.set(`${identifier}${key}`, filteredCol.key);
          }
        } else {
          newSearchParams.set(`${identifier}${key}`, value);
        }
      }
    });
    newSearchParams.set('page', '1'); // filtering resets the pagination
    setSearchParams(`?${newSearchParams.toString()}`);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilter({
      ...filters,
      [name]: value,
    });
  };

  const handleClear = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    setFilter({
      search: '',
      filterBy: labels[0] || '',
    });
    newSearchParams.delete(`${identifier}search`);
    newSearchParams.delete(`${identifier}filterBy`);

    setSearchParams(`?${newSearchParams.toString()}`);
  };

  const hasQueryParams = searchParams.get(`${identifier}search`) || searchParams.get(`${identifier}filterBy`);

  return (
    <div className="flex flex-wrap gap-7">
      <InputField
        label="Search"
        type="text"
        name="search"
        placeholder="Enter search text here"
        value={filters.search}
        onChange={handleChange}
        editable={true}
        containerClassName="flex justify-center items-center gap-4"
        labelClassName="font-bold text-[#195CE5] w-[50px] lg:w-auto"
      />
      <DropdownField
        label="By"
        name="filterBy"
        value={filters.filterBy}
        onChange={handleChange}
        options={labels}
        editable={true}
        containerClassName="flex justify-center items-center gap-4"
        labelClassName="font-bold text-[#195CE5] w-[50px] lg:w-auto"
      />
      <button className="flex justify-center gap-2 items-center bg-[#195CE5] text-white text-sm py-1 px-4 rounded-md hover:bg-[#3259a5]" onClick={handleFilter}>
        <MdFilterAlt color={'white'} size={'20px'} />
        Filter
      </button>
      {hasQueryParams && (
        <button className="flex justify-center gap-2 items-center bg-[#FF3B2F] text-white text-sm py-1 px-4 rounded-md hover:bg-[#d75750]" onClick={handleClear}>
          <RxCross2 color={'RxCross2'} size={'20px'} />
          Clear
        </button>
      )}
    </div>
  );
};

export default Filters;
