import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { PuffLoader } from 'react-spinners';

import { useVerifyEmail } from 'services/user/user.query';

import SuccessIcon from 'assets/Success.svg';

const EmailVerification: React.FC<{ token: string }> = ({ token }) => {
  const { status } = useVerifyEmail({ token });
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center mt-4 mx-4">
      {status === 'pending' && (
        <div className="flex gap-2 items-center">
          <h1 className="text-xl md:text-[20px] md:leading-[33px]  font-semibold">Verifying your email...</h1>
          <PuffLoader color="#195CE5" size={30} />
        </div>
      )}
      {status === 'error' && (
        <div>
          <h1 className="text-xl md:text-[20px] md:leading-[33px]  font-semibold">Unable to activate the email. Please try again later.</h1>
        </div>
      )}
      {status === 'success' && (
        <div className="flex flex-col gap-2 items-center">
          <img src={SuccessIcon} alt="SuccessIcon" />
          <h1 className="text-2xl font-bold">Congratulations!</h1>
          <p className=" text-center">Your account has been activated successfully.</p>
          <button className=" bg-vibe_blue py-4 mt-3  text-white text-1xl font-semibold rounded-lg w-full max-w-[368px] flex justify-center items-center gap-2" onClick={() => navigate('/')}>
            Countinue
          </button>
        </div>
      )}
    </div>
  );
};

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const token = searchParams.get('token');
  if (!token) {
    navigate('/login');
  }
  return <>{token ? <EmailVerification token={token} /> : <></>}</>;
};
export default VerifyEmail;
