import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUser, useSendVerficationEmail } from 'services/user/user.query';

const ActivationPage: React.FC = () => {
  const navigate = useNavigate();
  const [sendAttempts, setSendAttempts] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref for storing interval ID
  const { mutateAsync: sendVerificationEmail, isSuccess, isPending } = useSendVerficationEmail();
  const { data } = useGetCurrentUser();


  useEffect(()=>{
    if(data){
      if(data.is_email_verified){
        navigate('/home');
      }
    }
  }, [data])
  useEffect(() => {
    // Check and restore state from localStorage
    const savedAttempts = parseInt(localStorage.getItem('sendAttempts') || '0', 10) || 0;
    setSendAttempts(savedAttempts);

    intervalRef.current = setInterval(() => {
      setCounter((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        return 0;
      });
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleSendEmail = async () => {
    if (isPending || counter > 0 || sendAttempts >= 3) return;

    try {
      const user: any = localStorage.getItem('user');
      const parsedUser = JSON.parse(user);
      const userId = parsedUser?.user_id;
      await sendVerificationEmail({ email: parsedUser?.email });
      // Start the 60-second countdown
      setCounter(60);
      localStorage.setItem(`${userId}_lastAttemptTime`, Date.now().toString());
      localStorage.setItem(`${userId}_counter`, '60');

      // Increment sendAttempts and save to localStorage
      setSendAttempts((prev) => {
        const newAttempts = prev + 1;
        localStorage.setItem(`${userId}_sendAttempts`, newAttempts.toString());
        console.log('newAttempts', newAttempts);
        return newAttempts;
      });
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };
  useEffect(() => {
    // Reset sendAttempts after 2 hours
    const resetAttempts = () => {
      const now = Date.now();
      const user: any = localStorage.getItem('user');
      const parsedUser = JSON.parse(user);
      const userId = parsedUser?.user_id;
      const lastAttemptTime = parseInt(localStorage.getItem(`${userId}_lastAttemptTime`) || '0', 10);
      if (now - lastAttemptTime > 2 * 60 * 60 * 1000) {
        setSendAttempts(0);
        localStorage.setItem(`${userId}_sendAttempts`, '0');
      } else {
        const attempts = localStorage.getItem(`${userId}_sendAttempts`);
        setSendAttempts(parseInt(attempts || '0'));
      }
    };
    console.log('fef');
    resetAttempts();
  }, []);

  return (
    <div className="max-w-[800px] mx-auto flex flex-col items-center gap-2 px-5">
      <p>Click the button below to send a verification link to your email address. You will receive an email with a link to verify your account.</p>¸
      <button
        className=" bg-vibe_blue py-4 mb-3  text-white text-1xl font-semibold rounded-lg w-full max-w-[368px] flex justify-center items-center gap-2"
        onClick={handleSendEmail}
        disabled={isPending || counter > 0 || sendAttempts >= 3}
      >
        {isPending ? 'Sending...' : 'Send Activation Email'}
      </button>
      {counter > 0 && <p className=' text-center'>You can send another email in {counter} seconds.</p>}
      {sendAttempts >= 3 && <p>You have reached the maximum number of attempts.</p>}
    </div>
  );
};

export default ActivationPage;
