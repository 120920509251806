import SuccessIcon from 'assets/Success.svg';
import { Link, useNavigate } from 'react-router-dom';
const ForgetPasswordConfirmation = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-2  items-center my-14 px-4 max-w-[600px] mx-auto">
                  <h1 className="text-2xl font-bold">Update Password</h1>

      <img src={SuccessIcon} alt="SuccessIcon" />
      <h1 className="text-2xl font-bold">Congratulations!</h1>
      <p className=' text-center'>
        A reset password link has been sent to your email address. Please check your inbox (and spam folder, just in case) and click on the link to reset your password. Once your password is reset,
        you can <Link to="/login">log in</Link> and start using our services.
      </p>
      <button className="bg-vibe_blue py-4 mt-3 text-white text-1xl font-semibold rounded-lg w-full flex justify-center items-center gap-2 max-w-[368px]" onClick={() => navigate('/')}>
        Continue
      </button>
    </div>
  );
};

export default ForgetPasswordConfirmation;
