import { useQuery, useMutation } from '@tanstack/react-query';
import { loginUser, signUpUser, forgotPassword, resetPasswordVerification, updatePassword, getCurrentUser, viewUser, sendVerficationEmail, verifyEmail, fetchUsers } from './user.service';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IFilters } from 'types/common';
import { useNavigate } from 'react-router-dom';

const useLoginUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: loginUser,
    onSuccess(data, variables, context) {
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('token_type', data.token_type);
      queryClient.fetchQuery({ queryKey: ['current_user'], queryFn: getCurrentUser }).then((res) => {
        localStorage.setItem('user', JSON.stringify(res));
        navigate('/home');
      });
    },
  });
};

const useSignUpUser = () => {
  return useMutation({
    mutationFn: signUpUser,
    onSuccess(data, variables, context) {
      toast.success('Successfully signed up. Please login to continue');
    },
  });
};
const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      toast.success('Password reset link sent successfully.');
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
    retry: false,
  });
};
const useResetPasswordVerification = (token: string) => {
  return useQuery({
    queryKey: ['resetPasswordVerification', token],
    queryFn: () => resetPasswordVerification(token),
  });
};

const useUpdatePassword = () => {
  return useMutation({
    mutationFn: updatePassword,
    onSuccess: () => {
      toast.success('Password updated successfully.');
      window.location.href = '/login';
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
};
const useGetCurrentUser = () => {
  return useQuery({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  });
};

const useSendVerficationEmail = () => {
  return useMutation({
    mutationFn: sendVerficationEmail,
    onSuccess: () => {
      toast.success('Email sent successfully');
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
};

const useVerifyEmail = (payload: { token: string }) => {
  return useQuery({
    queryKey: ['verify_email', payload],
    queryFn: () => verifyEmail(payload),
  });
};

export const useGetUsers = ({ page = 1, limit = 10, search_field, search_value }: IFilters) => {
  return useQuery({
    queryKey: ['users', search_field, search_value, page, limit],
    queryFn: () =>
      fetchUsers({
        page,
        limit,
        search_field,
        search_value,
      }),
  });
};

const useGetSingleUser = ({ userId }: { userId: number }) => {
  return useQuery({
    queryKey: ['view-user', userId],
    queryFn: () =>
      viewUser({
        userId,
      }),
  });
};

const useGetAllUsers = () => {
  return useMutation({
    mutationFn: fetchUsers
  });
};
export {
  useLoginUser,
  useSignUpUser,
  useForgotPassword,
  useResetPasswordVerification,
  useUpdatePassword,
  useGetCurrentUser,
  useSendVerficationEmail,
  useVerifyEmail,
  useGetSingleUser,
  useGetAllUsers,
};
