import axios from 'config/axios.';
import { IListResponse } from 'types/services';
import { IAddListPayload, IAddListResponse, IEditListPayload, IEditListResponse, IListInterestResponse } from 'types/services';

const addList = async (payload: IAddListPayload): Promise<IAddListResponse> => {
  const response = await axios.post('/add-lists', payload);
  return response.data;
};
const getLists = async (user_id: number): Promise<IListResponse[]> => {
  const response = await axios.get('/get-user-lists', { params: { user_id } });
  return response.data;
};

const getUserListInterests = async (user_id: number, list_id: number): Promise<IListInterestResponse> => {
  const response = await axios.get(`/get-user-list-interests`, { params: { user_id, list_id } });
  return response.data;
};

const editList = async (payload: IEditListPayload): Promise<IEditListResponse> => {
  console.log('payload', payload);
  const response = await axios.put('/edit-list', payload);
  return response.data;
};

export { getLists, addList, getUserListInterests, editList };
