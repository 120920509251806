import SportsImage from 'assets/sportsImage.jpg';

import { Flat } from '@alptugidin/react-circular-progress-bar';
import { useLocation } from 'react-router-dom';
import { ResultSubCategory } from './Interests';

interface IntersetItemsProps {
  subCategory: ResultSubCategory;
}
interface IInterestCategory {
  interestType: string;
  matchPercentage: number;
  sub_categories: ResultSubCategory;
}
interface MatchStrength {
  match: number;
}

const IntersetItems = ({ subCategory }: IntersetItemsProps) => {
  const catagories = Object.entries(subCategory);
  const catagoryLen = catagories.length;
  console.log(catagories, '===>');
  return (
    <div className="rounded-2xl">
      <div className="text-lg font-medium bg-[#D7E7FF] rounded-tl-2xl rounded-tr-2xl p-4">Item</div>
      {/* Items List */}
      <div className="rounded-bl-2xl rounded-br-2xl bg-[#F7F6F9]">
        {catagories.map(([key, value], index) => (
          <div key={key} className={`text-base font-normal p-4 ${index !== catagoryLen - 1 && 'border-b border-b-[#D8DADC]'}`}>
            {key}- {value?.length || 0}
          </div>
        ))}
      </div>
    </div>
  );
};

const MatchStength: React.FC<MatchStrength> = ({ match }) => {
  return (
    <div className="rounded-2xl bg-[#F7F6F9] p-5 flex flex-col items-center gap-5 md:rounded-t-none">
      <h1 className="font-semibold text-2xl">Match Strength</h1>
      <div className="w-[133px]">
        <Flat
          progress={Math.round(match)}
          showMiniCircle={false}
          sx={{
            strokeColor: '#195CE5',
            bgStrokeColor: '#CBDFFF',
            barWidth: 10,
            valueColor: '#195CE5',
            textColor: '#195CE5',
            valueWeight: 'bold',
            valueSize: 27,
            miniCircleColor: '#195CE5',
          }}
        />
      </div>
    </div>
  );
};

const InterestDetails = () => {
  const { state } = useLocation();
  const category: IInterestCategory | undefined = state?.resultCatagory;

  if (!category) return <h1 className=" text-center text-red-500"> Oops, Category item of list does not exist</h1>;
  console.log(category, 'category');
  return (
    <div className="p-4 flex flex-col gap-4 max-w-[1100px] m-auto md:grid md:grid-cols-2 md:gap-7">
      <div className=" flex flex-col gap-4 md:gap-0">
        <div
          className="h-[150px] relative rounded-2xl bg-vibe_blue md:rounded-b-none"
          // style={{ backgroundImage: `url(${SportsImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        >
          <h1 className="font-bold text-[44px] text-white absolute bottom-2 left-5">{category.interestType}</h1>
        </div>
        <MatchStength match={category.matchPercentage} />
      </div>

      <IntersetItems subCategory={category.sub_categories} />
    </div>
  );
};
export default InterestDetails;
