import axios from 'config/axios.';
import { IEditProfilePayload } from 'types/services';

export const editProfile = async (payload: IEditProfilePayload) => {
  const response = await axios.put('/edit-profile', payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  if (response.status !== 200) {
    throw new Error(response.data.detail);
  }
  return response.data;
};
