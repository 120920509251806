import React, { ChangeEvent } from 'react';

interface DropdownFieldProps {
    label: string;
    name: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    required?: boolean;
    options: string[];
    editable?: boolean;
    containerClassName?: string;
    labelClassName?: string;
    labelOfCampaignViewLead?: string;
    selectClassName?: string;
}

const DropdownField: React.FC<DropdownFieldProps> = ({
    label,
    name,
    value,
    onChange,
    required = false,
    options,
    editable = false,
    containerClassName,
    labelClassName,
    labelOfCampaignViewLead,
    selectClassName,
}) => {
    return (
        <div className={`form-group ${containerClassName ?? 'flex flex-col md:flex-row  items-center w-full'}`}>
            <label
                className={`${labelClassName ?? `md:w-36 lg:w-56 font-bold ${labelOfCampaignViewLead ? labelOfCampaignViewLead : 'text-[#195CE5]'}`}`}
            >
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <select
                className={`${selectClassName ?? 'border border-[#195CE5] rounded-md px-1 h-8 md:w-56 w-full focus:outline-[#5f84d0]'}`}
                name={name}
                value={value || undefined}
                onSelect={e => console.log(e)}
                onChange={e => onChange(e)}
                required={required}
                disabled={!editable}
            >
                {options.length === 0 && <option value="">No option seleted</option>}
                {options.map((option, index) => (
                    <option key={option + index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownField;
