import { useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import { PuffLoader } from 'react-spinners';
import { useAddInterest, useGetInterests, useClearInterest } from 'services/interests/interest.query';
import { IUser } from 'types/common';
import { IInterestPage } from 'types/component';

const AddInterest = ({ type }: IInterestPage) => {
  const user: IUser = JSON.parse(localStorage.getItem('user') || '{}');
  const [interest, setInterest] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { isLoading, data: interests, isError } = useGetInterests({ type });
  const { mutateAsync: updateInterest, isError: mutationError, isPending } = useAddInterest({ type });
  const { mutateAsync: clearInterests } = useClearInterest();
  const handleEnter = () => {
    if (interest.trim() === '') return;
    const index = interests?.interests?.findIndex((obj) => obj.name === interest);
    if (index !== -1) {
      setError('Interest already exists!');
      setInterest('');
      setTimeout(() => {
        setError('');
      }, 5000);
      return;
    }
    updateInterest({
      type,
      new_interest_names: [interest],
      user_id: user.user_id,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setError(`Error occurred while adding ${type} interest`);
        setTimeout(() => {
          setError('');
        }, 5000);
      });
    setInterest('');
  };

  const handleRemove = (interestId: number, interest_name: string) => {
    updateInterest({
      type,
      remove_interest_ids: [interestId],
      user_id: user.user_id,
      action: 'delete',
    }).catch((err) => {
      setError(`Error occurred while deleting ${type} interest`);
      setTimeout(() => {
        setError('');
      }, 5000);
    });
  };

  const handleClearAll = () => {
    clearInterests({ type, user_id: user.user_id }).catch((err) => {
      setError(`Error occurred while deleting ${type} interest`);
      setTimeout(() => {
        setError('');
      }, 5000);
    });
  };

  if (isLoading) return <PuffLoader color="#195CE5" className=" m-auto" />;
  if (isError)
    return (
      <div>
        <h1 className=" text-center font-bold text-red-500 text-xl">An Error Ocurred Loading Interests!</h1>
      </div>
    );

  return (
    <div className="max-w-[568px] mx-auto">
      <div className="hidden md:block">
        <h1 className="text-[16px] leading-[25.6px] text-center w-[80%] text-[#7D7D7D] mb-8 mt-20 m-auto">
          {type === 'public'
            ? 'Add a list of your interests, which will be seen publicly.'
            : 'Add a list of your interests, which will not be seen publicly, but we can find the best recommendations for you.'}
        </h1>
      </div>
      <div className={`flex flex-col justify-between gap-7 mx-4 mt-6 md:min-h-[70vh] min-h-[76svh] mb-5`}>
        <div className="flex flex-col gap-4">
          <div className="flex justify-start items-center px-5 py-4 bg-[#F7F6F9] md:bg-white rounded-md w-[100%] md:gap-3 gap-2 md:border md:border-[#D8DADC]">
            <IoIosSearch size={22} color="#6B6B6B" />
            <input
              className="flex outline-none border-none md:bg-white bg-[#F7F6F9] text-[18px] w-full"
              placeholder={`Add ${type} interest`}
              value={interest}
              onChange={(e) => setInterest(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleEnter()}
            />
          </div>
          <div className="flex justify-between items-center">
            <p className="text-[#052B33] font-semibold leading-[22.5px] text-[20px]">Interests</p>
            {interests?.interests && interests.interests.length > 0 && (
              <div className="text-[16px] text-[#808080] leading-[18.2px]" onClick={() => handleClearAll()}>
                Clear All
              </div>
            )}
          </div>
          <div className="flex justify-start flex-wrap gap-2.5">
            {interests?.interests?.map((interest, index) => (
              <div key={index} className="flex justify-center gap-2 items-center bg-[#EBF0F5] py-1 px-3 rounded-full">
                <p className="text-[#052B33] text-[16px]">{interest.name}</p>
                <div className="flex items-center justify-center bg-[#D9D9D9] rounded-full p-0.5" onClick={() => handleRemove(interest.id, interest.name)}>
                  <RxCross2 size={10} color="#052B33" />
                </div>
              </div>
            ))}
            {isPending && <PuffLoader size={25} color="#195CE5" />}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4">{error && <p className="text-red-500 text-center">{error}</p>}</div>
      </div>
    </div>
  );
};

export default AddInterest;
