import { PuffLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import { useGetLists } from 'services/lists/list.query';
import { getUserData } from 'utils/utils';
import { IListResponse } from 'types/services';
import Select from 'react-select';
import CustomDropDown from 'components/ui/CustomDropDown';
import { customStyles } from 'constants/constants';

interface ISelectList {
  handleNextStep: () => void;
  selectedList?: IListResponse;
  setSelectedList: (list: IListResponse) => void;
  isPending?: boolean;
}

const SelectList: React.FC<ISelectList> = ({ handleNextStep, selectedList, setSelectedList, isPending = false }) => {
  const mockUser = getUserData();
  const { data: list, isLoading, isError } = useGetLists(mockUser);

  if (isLoading) return <PuffLoader color="#195CE5" className=" m-auto" />;

  if (isError)
    return (
      <div>
        <h1 className=" text-center font-bold text-red-500 text-xl">An Error Ocurred Loading Interests!</h1>
      </div>
    );

  const handleOnClick = () => {
    if (!selectedList) {
      toast.error('Please select a list to continue');
      return;
    }
    handleNextStep();
  };

  return (
    <div className="px-4 min-h-[80svh] md:min-h-[75svh] flex flex-col justify-between max-w-[400px] m-auto">
      <div>
        <h1 className=" font-bold mb-5 text-lg">Select list from below</h1>
        <Select
          placeholder="Select List"
          options={list?.map((item) => ({ value: item.list_id, label: item.list_name }))}
          name="location"
          onChange={(selectedOption: any) => {
            setSelectedList({
              list_id: selectedOption?.value,
              list_name: selectedOption?.label,
            });
          }}
          value={selectedList && { value: selectedList?.list_id, label: selectedList?.list_name }}
          isMulti={false}
          styles={customStyles}
          noOptionsMessage={() =>  "No list"} 
        />
      </div>
      <button className=" bg-vibe_blue py-4 mb-3  text-white text-1xl font-semibold rounded-lg w-full flex justify-center items-center gap-2 mt-5" onClick={handleOnClick}>
        <PuffLoader color="white" size={30} loading={isPending} />
        Submit
      </button>
    </div>
  );
};

export default SelectList;
