import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { createVibeSession } from './vibe.service';
import { joinVibeCheckSession } from './vibe.service';
import { getVibeParticipants } from './vibe.service';
import { syncVibe } from './vibe.service';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IGetVibeParticipantsResponse } from 'types/services';

const useCreateVibeSession = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['create-vibe-check'],
    mutationFn: createVibeSession,
    onError: (error, variables, context) => {
      toast.error('Error occurred while creating vibe check');
    },
  });
};

const useJoinVibeSession = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['join-vibe-check'],
    mutationFn: joinVibeCheckSession,
    onSuccess: (data, variables, context) => {
      toast.success(data.message);
      navigate('/home/vibe-match', { state: { vibeCheck: { vibe_id: data.vibe_id } } });
    },
    onError: (error, variables, context) => {
      //@ts-ignore
      toast.error(error?.response?.data?.detail || 'Error occurred while joining vibe check');
    },
  });
};

const useGetVibeParticipants = (vibe_id: number) => {
  return useQuery({
    queryKey: ['get-vibe-participants', vibe_id],
    queryFn: () => getVibeParticipants(vibe_id),
  });
};

const useSyncVibe = () => {
  return useMutation({
    mutationKey: ['sync-vibe'],
    mutationFn: syncVibe,
    onSuccess(data, variables, context) {
      toast.success('Synced successfully');
    },
    onError: (error, variables, context) => {
      toast.error('Error occurred while syncing vibe check');
    },
  });
};

export { useCreateVibeSession, useJoinVibeSession, useGetVibeParticipants, useSyncVibe };
