import axios from 'config/axios.';
import axiosWithoutParams from 'config/axiosWithoutParams';
import { IUser } from 'types/common';
import { IAddInterestPayload, IAddInterestResponse, IInterestsResponse, IInterestPayload, IClearInterestPayload } from 'types/services';

const updateInterest = async ({ type = 'public', action = 'add', ...payload }: IAddInterestPayload): Promise<IAddInterestResponse> => {
  let endpoint = `${action === 'add' ? 'add' : 'delete'}-${type === 'public' ? 'public' : 'private'}-interests`;
  const response = await axios.post(endpoint, payload);

  return response.data;
};

const clearInterests = async ({ type = 'public', ...payload }: IClearInterestPayload): Promise<IClearInterestPayload> => {
  let endpoint = `clear-${type === 'public' ? 'public' : 'private'}-interests`;
  const response = await axios.post(endpoint, payload);
  return response.data;
};

const getInterests = async ({ type = 'public', userId }: IInterestPayload): Promise<IInterestsResponse> => {
  const params: { user_id?: number } = {};
  if (!userId) {
    const userString = localStorage.getItem('user');
    if (!!userString && userString !== 'undefined') {
      const user: IUser = JSON.parse(userString);
      params['user_id'] = user.user_id;
    }
  } else {
    params['user_id'] = userId;
  }
  const endpoint = type === 'public' ? 'get-user-public-interests' : 'get-user-private-interests';
  const response = await axiosWithoutParams.get(endpoint, {
    params,
  });
  return response.data;
};

export { updateInterest, getInterests, clearInterests };
