import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Pagination from './Pagination';

interface IPaginationWrapper {
  totalPages: number;
  currentPage: number;
  total_instances: number;
  identifier?: string;
}

const PaginationWrapper: React.FC<IPaginationWrapper> = ({ totalPages, currentPage, total_instances, identifier }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const activePage = Number(searchParams.get('page')) || 1;

  const handleChange = (val: number) => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(`${identifier}page`, val.toString());

    navigate(`?${newSearchParams.toString()}`);
  };

  return (
    <>
      <Pagination totalPages={totalPages} activePage={currentPage} updatePageNumber={handleChange} totalCount={total_instances} />
    </>
  );
};
export default PaginationWrapper;
