import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getUserData } from 'utils/utils';
import { useGetInterests } from 'services/interests/interest.query';
import { useGetCountries } from 'services/countries/countries.query';
import { useEditProfile } from 'services/profile/profile.query';
import { snakeToTitleCase } from 'utils/utils';
import { IUser } from 'types/common';
import { useNavigate } from 'react-router-dom';

import ProfileDetailsEditor from './ProfileDetailsEditor';

type Item = {
  label: string;
  value: string;
};

const EditProfile = () => {
  const { data: publicInterests, isLoading } = useGetInterests({ type: 'public' });
  const { mutateAsync: editProfile, isPending: profileUpdatePending } = useEditProfile();
  const { data: countries, isLoading: countriesLoading } = useGetCountries();
  const [user, setUser] = useState<IUser>(getUserData());
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>(user?.image || '');

  const navigate = useNavigate();

  const [values, setValues] = useState({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    biography: user?.biography || '',
    location: user?.location || { label: '', value: '' },
    username: user?.username || '',
  });
  const [edit, setEdit] = useState(false);

  const handleSave = () => {
    const required_fields = {
      username: values.username.trim(),
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
    };

    let missing_fields: string[] = [];

    Object.entries(required_fields).forEach(([key, value]) => {
      if (!value) {
        missing_fields.push(snakeToTitleCase(key));
      }
    });

    if (missing_fields.length > 0) {
      toast.error(`${missing_fields.join(', ')} ${missing_fields.length > 1 ? 'are' : 'is'} required`);
      return;
    }
    if (values.username) {
      const isValid = /^[a-z0-9]*$/i.test(values.username);
      if (!isValid) {
        toast.error('Username should be alphanumeric');
        return;
      }
    }

    if (values.username)
      editProfile({
        username: values.username,
        first_name: values.first_name,
        last_name: values.last_name,
        biography: values.biography,
        user_id: user?.user_id,
        //@ts-ignore
        location: values.location.label,
        ...(image && { image: image }),
      })
        .then((res) => {
          console.log(res);
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...user,
              ...res,
            })
          );
          navigate('/profile');
        })
        .catch((err) => {
          toast.error(err?.response?.data?.detail || 'Error occurred while editing profile');
        });
  };
  //  useEffect(() => {
  //   const handleSaveProfile = () => handleSave();
  //   window.addEventListener('saveProfile', handleSaveProfile);

  //   return () => {
  //     window.removeEventListener('saveProfile', handleSaveProfile);
  //   };
  // }, []);

  useEffect(() => {
    if (!countriesLoading && countries) {
      const country = countries.find((country: Item) => country.label === user?.location);
      if (country) {
        setValues((prevValues) => ({ ...prevValues, location: country }));
      }
    }
    const userItem = localStorage.getItem('user');
    if (userItem) setUser(JSON.parse(userItem));
  }, [countriesLoading, countries]);

  const hanldeUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = function (event: any) {
      const file = event.target?.files[0];
      if (file) {
        setImage(file);
        const reader = new FileReader();
        reader.onload = function (e) {
          setImagePreview(e.target?.result as string);
        };
        reader.readAsDataURL(file);
        if (!edit) setEdit(true);
      }
    };
    input.click();
  };
  return (
    <div>
      <ProfileDetailsEditor
        profileUpdatePending={profileUpdatePending}
        setValues={setValues}
        countries={countries}
        countriesLoading={countriesLoading}
        isLoading={isLoading}
        publicInterests={publicInterests}
        setEdit={setEdit}
        handleSave={handleSave}
        hanldeUpload={hanldeUpload}
        edit={edit}
        imagePreview={imagePreview}
        values={values}
        email={user?.email || ''}
        location={user?.location}
        readOnly={false}
      />
    </div>
  );
};
export default EditProfile;
