import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

const ROUTE_MAPPING = {
  '/list': 'Lists',
  '/profile': 'Profile',
  '/add-interest': 'Private Interests',
  'profile/add-interest': 'Public Interests',
  '/join-vibe-check': 'Vibe Check In',
  '/profile/edit': 'Edit Profile',
  '/home/vibe-match/interests': 'Interests',
  '/home/join-vibe-check/scan-qr': 'Scan QR',
  '/home/join-vibe-check': 'Vibe Check In',
  '/profile/edit/add-interest': 'Public Interests',
  '/activate-account': 'Email Activation',
  '/verify-email': 'Account Activation',
  '/users': 'Users',
  '/view-user': 'User Details',
};

const getName = (pathname: any, ROUTE_MAPPING: any) => {
  let key = Object.keys(ROUTE_MAPPING).find((path) => pathname === path);
  if (!key) key = Object.keys(ROUTE_MAPPING).find((path) => pathname.startsWith(path));
  return key ? ROUTE_MAPPING[key] : 'Just Vibes';
};
export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  console.log(pathname, pathname.split('/').length > 1, pathname.split('/'));
  const hasBack = pathname.split('/').length > 2;

  const message = getName(pathname, ROUTE_MAPPING);

  // const handleSave = () => {
  //   window.dispatchEvent(new Event("saveProfile"));
  //   editRef.current = false;
  // }
  return (
    <div className="sticky md:top-[70px] top-0 bg-white z-10">
      {pathname !== '/login' && pathname !== '/sign-up' ? (
        <div className="py-4 md:pt-12 md:pb-6 relative text-center">
          {hasBack && (
            <IoIosArrowBack size={25} className="absolute lg:left-[8rem] left-5 md:top-[1rem] top-[1.25rem] md:border-0 border border-black rounded-md cursor-pointer" onClick={handleBack} />
          )}
          <h1 className="text-2xl md:text-[26px] md:leading-[33px] md:font-bold font-semibold">{message}</h1>
          {/* {pathname === "/profile/edit" && editRef.current && (
          <button className="absolute right-5 top-[1.25rem]" onClick={() => handleSave()}>
            <span className="text-[#195CE5] text-[16px] leading-[20.5px] font-medium">Save</span>
          </button>
        )} */}
        </div>
      ) : null}
    </div>
  );
};
