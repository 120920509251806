import { IProfileCard } from 'types/component';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as Edit } from 'assets/editProfileImage.svg';
import waveHandSVG from 'assets/wave.png';
import { imgLink } from 'constants/constants';
export const ProfileCard: React.FC<IProfileCard> = ({ name, image, greetings, email, containerClass, type, classForName, location, imageContainer, edit, buttonClick }) => {
  const nameCLass = twMerge('font-bold  capitalize  max-w-[300px] text-center  text-ellipsis whitespace-nowrap overflow-hidden', greetings && 'md:text-2xl text-3xl');
  return (
    <div className={twMerge('md:bg-white bg-[#F7F6F9] flex flex-col items-center md:flex-row gap-2 md:gap-4 py-4 justify-center rounded-xl w-full m-auto', containerClass)}>
      <div className={imageContainer ? imageContainer : ''}>
        <img
          src={image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = imgLink;
          }}
          className="w-[110px] md:w-[126px] h-[110px] md:h-[126px] border rounded-full "
          alt="profile-card"
        />
        {edit && (
          <div className="p-[2px] bg-white absolute top-[96px] right-0 rounded-full">
            <button onClick={buttonClick} className="rounded-full bg-[#195CE5] p-2">
              <Edit className="w-[14px] h-[14px] " />
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-center md:justify-start md:items-start max-w-[70%] md:gap-1">
        <h1 className={`${classForName ? classForName : nameCLass} text-ellipsis overflow-hidden whitespace-nowrap max-w-[100%] capitalize md:leading-[33.5px]`}>
          {' '}
          {!!greetings && 'Hi,'} {name}{' '}
          {!!greetings && (
            <span>
              <img src={waveHandSVG} alt="Wave" className="inline-block h-8 w-8 ml-0 mr-1 mb-3" />
            </span>
          )}
        </h1>
        <p className="opacity-40 text-center text-[16px]">{greetings}</p>
        {type && type === 'profile' && <p className="text-[#828282] text-[16px] leading-[18.2px] md:leading-[25.6px] font-medium text-center md:mt-1">{email}</p>}
        {location && <p className="text-[#828282] text-[16px] leading-[18.2px] md:leading-[25.6px] font-medium text-center">{location}</p>}
      </div>
    </div>
  );
};
