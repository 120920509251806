import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectList from 'components/ui/SelectList';
import { IListResponse } from 'types/services';

import { ReactComponent as EnterCodeSvg } from 'assets/enterCode.svg';
import { ReactComponent as ScanQR } from 'assets/scanQr.svg';
import { twMerge } from 'tailwind-merge';

const ChooseMethodToCheckIn = ({ selectedListId }: { selectedListId?: number }) => {
  const navigate = useNavigate();
  return (
    <>
      <h1 className=" font-bold text-center w-[68%] m-auto pb-10 text-lg">How would you like to check in?</h1>
      <div className="flex gap-3 justify-center">
        <div
          className=" flex flex-col justify-center items-center border border-gray-300 rounded-3xl h-40 w-40 bg-white"
          onClick={() => navigate('join-otp', { state: { selectedList: selectedListId } })}
        >
          <EnterCodeSvg width={75} height={75} />
          <p className=" text-2xl text-gray-300 font-semibold">Enter Code</p>
        </div>
        <div
          className=" flex flex-col justify-center items-center border border-gray-300 rounded-3xl h-40 w-40 bg-white"
          onClick={() => navigate('scan-qr', { state: { selectedList: selectedListId } })}
        >
          <ScanQR width={75} height={75} />
          <p className=" text-2xl text-gray-300 font-semibold">Scan QR</p>
        </div>
      </div>
    </>
  );
};

const JoinVibeCheck: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [selectedList, setSelectedList] = useState<IListResponse | undefined>();

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const STEPPER = [<SelectList handleNextStep={handleNextStep} selectedList={selectedList} setSelectedList={setSelectedList} />, <ChooseMethodToCheckIn selectedListId={selectedList?.list_id} />];
  return <div className={twMerge(' h-[80svh] md:h-[75svh] rounded-3xl max-w-[800px] md:m-auto mx-5', step && 'pt-20 bg-[#F7F6F9]')}>{STEPPER[step]}</div>;
};

export default JoinVibeCheck;
