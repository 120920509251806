import { Outlet } from 'react-router-dom';
import { Header } from './functional/Header/Header';

export const HeaderLayout = () => {
  return (
    <div className={`md:h-[100vh] h-[calc(100svh-70px)] overflow-auto`}>
      <Header />
      <div className="md:mt-[70px]">
        <Outlet />
      </div>
    </div>
  );
};
