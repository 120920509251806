import { INavItem } from 'types/component';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export const NavigationItem: React.FC<INavItem> = ({ Icon, name, redirectTo, size, color, ...rest }) => {
  const { pathname } = useLocation();
  const defaultColor = pathname === redirectTo ? 'blue' : 'black';
  return (
    <Link to={redirectTo} className="flex flex-col items-center mt-2">
      <Icon color={color || defaultColor} size={size || 30} {...rest} />
      <h1 style={{color}}>{name}</h1>
    </Link>
  );
};
