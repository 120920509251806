import TextField from 'components/TextField/TextField';
import { useState, useEffect } from 'react';
import { useForgotPassword } from 'services/user/user.query';
import SuccessIcon from 'assets/Success.svg';
import { Link, useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
const ForgotPassword: React.FC = () => {
  const [values, setValues] = useState({
    email: '',
  });
  const navigate = useNavigate();
  const forgotPasswordMutation = useForgotPassword();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    forgotPasswordMutation.mutateAsync({ email: values.email }).then(() => {
      navigate(`/forgot-password/confirmation/${values.email}`);
    });
  };

  return (
    <div className="max-w-[400px] mx-auto my-14">
      <form onSubmit={handleSubmit} className="flex flex-col gap-7 h-[100svh] mx-4">
        <div className="">
          <div className="flex justify-center items-center mb-5 flex-col">
            <h1 className="text-3xl font-bold text-[#052B33] ">Update Password</h1>
            <p className='mt-5 text-center'>
            A password reset link will be sent to your email so you can update your password.
            </p>
          </div>
          
          <div className="flex flex-col items-center gap-4">
            <TextField label="Email" placeholder="Enter email" onChange={setValues} field="email" type="email" required={true} value={values.email} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
          <button
            className={`flex justify-center items-center w-full bg-blue-600 rounded-md text-center text-white py-3 font-semibold gap-3 disabled:opacity-40`}
            disabled={forgotPasswordMutation.isPending}
          >
            Send
            {forgotPasswordMutation.isPending && <PuffLoader size={16} color="#052B33" />}
          </button>
          <p>
            Back to{' '}
            <Link to="/login" className="underline cursor-pointer">
              Login
            </Link>{' '}
          </p>
        </div>
      </form>
    </div>
  );
};
export default ForgotPassword;
