import TableWrapper from 'components/Table/TableWrapper';

import { useSearchParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { CELL_TYPE } from 'constants/constants';
import { useGetUsers } from 'services/user/user.query';
import { useGetAllUsers } from 'services/user/user.query';

import { generateData } from 'utils/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITableColumns } from 'types/common';
import { INode } from 'types/table';
import { writeFile, utils } from 'xlsx';
import { toast } from 'react-toastify';

const USER_TABLE_COLOUMS: ITableColumns[] = [
  { cellType: CELL_TYPE.IMAGE, label: 'Image', key: 'image' },
  { cellType: CELL_TYPE.TEXT, label: 'First Name', key: 'first_name' },
  { cellType: CELL_TYPE.TEXT, label: 'Last Name', key: 'last_name' },
  { cellType: CELL_TYPE.TEXT, label: 'Username', key: 'username' },
];

const Users = () => {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [searchParams] = useSearchParams();

  // cont {mutat} = useGetAllUsers()
  const { mutateAsync: getAllUsers, isPending } = useGetAllUsers();

  const filters = {
    page: Number(searchParams.get('page')) || 1,
    limit: 10,
    search_field: searchParams.get('filterBy') || '',
    search_value: searchParams.get('search') || '',
  };
  const { data, isFetching, isError } = useGetUsers(filters);

  useEffect(() => {
    let generated_data: INode[] = [];
    if (data?.users && data?.users?.length > 0) {
      generated_data = generateData(data.users, USER_TABLE_COLOUMS);
      generated_data = generated_data.map((element: INode) => ({
        ...element,
        '': {
          value: [
            {
              type: 'VIEW',
              handleOnClick: () => {
                navigate(`/view-user/${element.id}`);
              },
            },
          ],
          cellType: 'BUTTONS',
        },
      }));
      //@ts-ignore
      setNodes(generated_data);
    } else {
      setNodes([]);
    }
  }, [data, navigate]);

  const handleExportUsers = () => {
    console.log('data, ===>', data);

    getAllUsers({ limit: 1000000 })
      .then((res) => {
        const users = res?.users.map((user) => ({
          Image: user.image,
          'First Name': user.first_name,
          'Last Name': user.last_name,
          Username: user.username,
          Email: user.email,
          Location: user.location?.replace(/[\u{1F1E6}-\u{1F1FF}]/gu, '')?.trim(),
          Biography: user.biography,
          'Public Interests': user.public_interests.join(', '),
        }));
        console.log('users', users);

        // Create a worksheet from the users array
        const ws = utils.json_to_sheet(users);

        // Create a new workbook and add the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Users');

        // Export the workbook to a file
        writeFile(wb, 'Users.xlsx');
      })
      .catch((err) => {
        toast.error('An error occured while exporting the users');
        console.error('Error exporting users:', err);
      });
  };
  if (isError) return <p className="text-red-500">An error occured while fetching the data, Please try again later!</p>;

  return (
    <div className="px-4 md:px-14 lg:px-52">
      <div className="flex justify-end mb-5">
        <button
          className=" bg-vibe_blue py-2 px-4 mb-3  text-white text-1xl font-semibold rounded-lg flex justify-center items-center gap-2 disabled:opacity-40"
          onClick={handleExportUsers}
          disabled={!data?.users?.length || data?.users?.length === 0}
        >
          <PuffLoader color="white" size={30} loading={isPending} />
          Export Users
        </button>
      </div>
      <TableWrapper nodes={nodes} pagination={data?.metadata} columns={USER_TABLE_COLOUMS} loading={isFetching} />
    </div>
  );
};

export default Users;
