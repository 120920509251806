import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const LoginRedirect = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, []);
  return (
    <>
      <h1>Yoo</h1>
    </>
  );
};
