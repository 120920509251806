import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { useSyncVibe } from 'services/vibe_check/vibe.query';

import { IVibeCheck } from 'types/common';
import { getUserData } from 'utils/utils';
import { useGetVibeParticipants } from 'services/vibe_check/vibe.query';
import { twMerge } from 'tailwind-merge';

import io from 'socket.io-client';
import { IGetVibeParticipantsResponse } from 'types/services';
import { useQueryClient } from '@tanstack/react-query';
import { imgLink } from 'constants/constants';

const socket = io(process.env.REACT_APP_BACKEND_URL as string);

interface VibeMatchProps {
  userName: string;
  email: string;
  image: string;
  first_name: string;
  last_name: string;
}

const VibeMatchWithUser: React.FC<VibeMatchProps> = ({ userName, email, image, first_name, last_name }) => {
  const { state } = useLocation();
  const vibeCheck: IVibeCheck = state?.vibeCheck;
  return (
    <div>
      <div className="flex items-center gap-4">
        <img className="rounded-full h-[69px] w-[69px]" src={image || imgLink} alt="Profile Pic" />
        <div className="text-start flex flex-col gap-1">
          <p className="text-[24px] font-normal leading-[31.2px]">
            {first_name} {last_name}
          </p>
          <p className="text-[#637087] text-sm">@{userName}</p>
        </div>
      </div>
      <div className="w-full h-[1px] bg-[#D8DADC] mt-5" />
    </div>
  );
};

export interface IVibeMatchProps {
  vibe_id: number;
  vibe_creator_id?: number;
}

const VibeMatch: React.FC<{ vibeCheck?: IVibeMatchProps }> = ({ vibeCheck }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const vibe: IVibeMatchProps = vibeCheck || state?.vibeCheck;
  const user = getUserData();
  const { mutate: syncVibe, isPending: syncPending } = useSyncVibe();
  const vibeId = vibe?.vibe_id;
  const { data: vibeParticipants, isPending, isError } = useGetVibeParticipants(vibeId);
  const queryClient = useQueryClient();
  useEffect(() => {
    const handleUpdateVibeSession = (newUser: IGetVibeParticipantsResponse) => {
      console.log('Hi from socket', newUser);

      queryClient.setQueryData(['get-vibe-participants', vibeId], (oldData: IGetVibeParticipantsResponse[]) => {
        if (!oldData) return [newUser];
        const isUserAlreadyPresent = oldData.some((user) => user.user_id === newUser.user_id);
        if (!isUserAlreadyPresent) {
          return [...oldData, newUser];
        }
        return oldData;
      });
    };

    const handleSyncVibe = (syncData: any) => {
      console.log(syncData);
      if(vibeId === syncData.vibe_id){
        delete syncData.vibe_id
      navigate('/home/interests', { state: { vibeReesult: syncData } });
      }
    };
    socket.on('update_vibe_session', handleUpdateVibeSession);

    socket.on('sync_vibe', handleSyncVibe);

    return () => {
      socket.off('update_vibe_session', handleUpdateVibeSession);
      socket.off('sync_vibe', handleSyncVibe);
    };
  }, []);

  const handleSyncVibe = () => {
    if (vibeId) {
      syncVibe({ vibe_id: vibeId });
    }
  };

  useEffect(() => {
    if (!vibeId) {
      navigate('/home');
    }
  }, []);

  if (isPending) {
    return <PuffLoader color="#195CE5" className=" m-auto" />;
  }

  if (isError) {
    return <h4 className="text-center text-xl font-bold ">Oops! Something went wrong. Unable to retrieve the list of participants</h4>;
  }

  return (
    <div className={twMerge('p-4 flex flex-col gap-5 max-w-[800px] m-auto', vibe.vibe_creator_id && user.user_id === vibe.vibe_creator_id && 'p-0')}>
      {vibe.vibe_creator_id && user.user_id === vibe.vibe_creator_id && (
        <button
          className={`rounded-[10px] bg-[#195CE5] active:bg-[#3a5997] w-full max-w-[368px] mx-auto p-4 text-lg text-white font-bold inline-flex items-center justify-center gap-2
                    ${(syncPending || vibeParticipants.length < 2) && ' opacity-60'}`}
          disabled={syncPending || vibeParticipants.length < 2}
          onClick={handleSyncVibe}
        >
          <PuffLoader color="white" size={30} loading={syncPending} />
          Sync
        </button>
      )}
      <h1 className=" font-bold text-lg">Participants</h1>
      <div className="flex flex-col gap-5">
        {vibeParticipants?.map(
          (participant, index) =>
            user.user_id !== participant.user_id && (
              <VibeMatchWithUser
                key={participant.user_id}
                userName={participant.username}
                email={participant.email}
                image={participant.image}
                first_name={participant.first_name}
                last_name={participant.last_name}
              />
            )
        )}
      </div>
    </div>
  );
};

export default VibeMatch;
