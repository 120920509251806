import React, { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import Select from 'react-select';
import { useGetInterests } from '../../services/interests/interest.query';
import { useNavigate } from 'react-router-dom';
import { useAddList } from '../../services/lists/list.query';
import { getUserData } from 'utils/utils';
import { PuffLoader } from 'react-spinners';
import { twMerge } from 'tailwind-merge';
import { CiSearch } from 'react-icons/ci';
import TextField from 'components/TextField/TextField';

interface Interest {
  id: number;
  name: string;
}

interface SelectedInterest {
  id: number;
  label: string;
}

const Pill: React.FC<{ label: string; onRemove: () => void }> = ({ label, onRemove }) => (
  <div className="flex justify-center gap-2 items-center py-1 px-3 rounded-full bg-[#ebf0f5] cursor-pointer">
    <p className="text-[16px]">{label}</p>
    <FiX onClick={onRemove} />
  </div>
);

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '50px',
    height: '50px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: '50px',
    padding: '0 6px',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '50px',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const AddList: React.FC = () => {
  const mockUser = getUserData();
  const { data: interestsData, isLoading, isError } = useGetInterests({ type: 'private' });
  const addListMutation = useAddList();
  const navigate = useNavigate();

  const [selectedInterests, setSelectedInterests] = useState<SelectedInterest[]>([]);
  const [listName, setListName] = useState({ list_name: '' });
  const [isEdit, setIsEdit] = useState(false);

  const handlePillRemove = (id: number) => {
    setSelectedInterests(selectedInterests.filter((interest) => interest.id !== id));
  };

  const handleSelectChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      if (!selectedInterests.some((interest) => interest.id === selectedOption.value)) {
        setSelectedInterests([...selectedInterests, { id: selectedOption.value, label: selectedOption.label }]);
      }
    }
  };

  const handleSave = () => {
    const interest_ids = selectedInterests.map((interest) => interest.id);
    const payload = {
      user_id: mockUser.user_id,
      list_name: listName.list_name,
      interest_ids,
    };

    addListMutation.mutate(payload, {
      onSuccess: () => {
        navigate('/list');
      },
      onError: (error) => {
        console.error('Error adding list', error);
      },
    });
  };

  if (isLoading) return <PuffLoader color="#195CE5" className="m-auto" />;
  if (isError)
    return (
      <div>
        <h1 className="text-center font-bold text-red-500 text-xl">An Error Occurred Loading Interests!</h1>
      </div>
    );

  const selectedInterestsSet = new Set(selectedInterests.map((interest) => interest.id));
  const interestOptions = interestsData
    ? interestsData.interests
        .map((interest) => {
          if (selectedInterestsSet.has(interest.id)) return null;
          return { value: interest.id, label: interest.name };
        })
        .filter(Boolean)
    : [];

  return (
    <div className="flex flex-col justify-between min-h-[81svh] max-w-[400px] m-auto md:min-h-[75svh]  px-4">
      <div className="flex flex-col">
        <div className="mt-4 flex items-center justify-between break-all">
          <TextField label="List Name" placeholder="Enter list name" value={listName.list_name} onChange={setListName} field="list_name" required={true} edit={isEdit} setEdit={setIsEdit} />
        </div>
        <p className="text-[#052B33] font-semibold leading-[22.5px] text-[20px] mt-2">Interests</p>
        <Select options={interestOptions} onChange={handleSelectChange} placeholder="Select interests" value={null} className="mt-2" styles={customStyles} />
        <div className="mt-4 flex flex-wrap gap-2">
          {selectedInterests.map((interest) => (
            <Pill key={interest.id} label={interest.label} onRemove={() => handlePillRemove(interest.id)} />
          ))}
        </div>
      </div>
      <button
        onClick={handleSave}
        disabled={addListMutation.isPending ? true : !listName.list_name ? true : selectedInterests.length === 0 ? true : false}
        className={twMerge(
          'bg-vibe_blue py-3 my-3 text-white text-1xl font-semibold rounded-lg flex flex-row justify-center items-center w-full max-w-[368px] mx-auto',
          (!listName.list_name || selectedInterests.length === 0) && 'opacity-65'
        )}
      >
        <PuffLoader color="white" loading={addListMutation.isPending} size={25} />
        Save
      </button>
    </div>
  );
};

export default AddList;
