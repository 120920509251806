import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { IOTP } from 'types/component';
import { twMerge } from 'tailwind-merge';
import './styles.css';
export const OTP: React.FC<IOTP> = ({ onChange, numInputs, renderSeparator, renderInput, skipDefaultStyles, inputStyle, disabled, ...rest }) => {
  return (
    <div className={twMerge('flex justify-center h-20 ', disabled && 'pointer-events-none')}>
      <OtpInput
        onChange={(e) => onChange?.(e)}
        numInputs={6}
        renderSeparator={<span>&nbsp;</span>}
        renderInput={(props) => <input {...props} />}
        skipDefaultStyles={false}
        inputStyle={{
          width: '45px',
          height: '45px',
          fontSize: '16px',
          border: '1px solid rgba(128, 128, 128, 0.3)',
          borderRadius: '15%',
        }}
        {...rest}
      />
    </div>
  );
};
