import { NavigationItem } from 'hoc/TabNavigation/NavigationItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { NO_HOC_ROUTES, ONLY_LOGO_NAVBAR_ROUTES } from 'constants/constants';
import { GoHome, GoHomeFill } from 'react-icons/go';
import { PiBookOpen, PiBookOpenFill } from 'react-icons/pi';
import { CiCirclePlus } from 'react-icons/ci';
import { VscAccount } from 'react-icons/vsc';
import { GoSignOut } from 'react-icons/go';
export const TabNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const fillicon = pathname === redirectTo ? 'blue' : 'black'

  return (
    <div>
      {!NO_HOC_ROUTES.some((value) => pathname.includes(value)) ? (
        <div className=" fixed bottom-0 h-[70px] bg-white m-auto w-full flex border border-gray-300 items-center justify-around z-10 ">
          {!ONLY_LOGO_NAVBAR_ROUTES.some((value) => pathname.includes(value)) ? (
            <>
              <NavigationItem Icon={pathname === '/home' ? GoHomeFill : GoHome} name="Home" redirectTo="/home" />
              <NavigationItem Icon={pathname === '/list' ? PiBookOpenFill : PiBookOpen} name="Lists" redirectTo="/list" />
              <NavigationItem Icon={CiCirclePlus} name="Interests" redirectTo="/add-interest" />
              <NavigationItem Icon={VscAccount} name="Profile" redirectTo="/profile" className="my-[1px]" size={26} />{' '}
            </>
          ) : (
            <NavigationItem
              Icon={GoSignOut}
              name="Logout"
              redirectTo="/login"
              color="red"
              onClick={(event) => {
                event.preventDefault();
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                localStorage.removeItem('user');
                navigate('/login');
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
