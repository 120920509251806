import Table from 'components/Table/Table';
import Filters from '../Filters/Filters';
import PaginationWrapper from 'components/Pagination/PaginationWrapper';
import React from 'react';

import { ITableColumns } from 'types/common';
import { PuffLoader } from 'react-spinners';
import { INode } from 'types/table';

interface ITableWrapper {
  pagination?: IPagination;
  nodes: INode[];
  columns: ITableColumns[];
  loading: boolean;
  identifier?: string;
}

interface IPagination {
  current_page: number;
  has_next_page: boolean;
  has_previous_page: boolean;
  total_page: number;
  total_instances: number;
}

const TableWrapper: React.FC<ITableWrapper> = ({ nodes, pagination, columns, loading, identifier = '' }) => {
  return (
    <div className="flex flex-col gap-6 relative">
      <Filters columns={columns} identifier={identifier} />
      {loading ? (
        <div className=" flex justify-center">
          <PuffLoader size={25} color="#00CED1" />
        </div>
      ) : nodes && nodes.length > 0 ? (
        <Table nodes={nodes} />
      ) : (
        <p className="text-center p-5 font-medium">No data available</p>
      )}
      {pagination && <PaginationWrapper currentPage={pagination.current_page} totalPages={pagination.total_page} total_instances={pagination?.total_instances} identifier={identifier} />}
    </div>
  );
};

export default TableWrapper;
