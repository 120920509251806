import { ITableColumns } from 'types/common';
import { IGeneratedData } from 'types/common';
import { INode } from 'types/table';
import { IUser } from 'types/common';

const getUserData = () => {
  const userString = localStorage.getItem('user');
  if (userString === null)
    return {
      user_id: 7,
      username: '',
      first_name: '',
      last_name: '',
      biography: '',
      location: '',
    };
  const user: IUser = JSON.parse(userString);
  return user;
};

const snakeToTitleCase = (str: string) => {
  return str.replace(/_/g, ' ').replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};

const getToken = () => {
  const token = localStorage.getItem('access_token');
  const type = localStorage.getItem('token_type');

  return token && type ? `${type} ${token}` : null;
};

export { getUserData, snakeToTitleCase, getToken };

export interface IData {
  [key: string]: string | number | boolean | string[];
}

// export type IGeneratedDataWithID = {
//     id: number;
//     status?: string;
// } & IGeneratedData;

export const generateData = (data: IData[], tableColumns: ITableColumns[]): INode[] => {
  const nodes = data.map((row) => {
    let entries: IGeneratedData = {};
    tableColumns.forEach((columnDetail) => {
      entries[columnDetail.label] = {
        cellType: columnDetail.cellType,
        value: row[columnDetail.key],
        className: columnDetail.className || '',
      };
    });
    if (row.status) return { ...entries, id: row.id as number, status: row.status as string };
    return { ...entries, id: row.id as number };
  });
  // @ts-ignore
  return nodes;
};

// const convertToCSV = (data: any) => {
//   const headers = Object.keys(data[0]);
//   const rows = data.map((row) => headers.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
//   return [headers.join(','), ...rows].join('\r\n');
// };

// const downloadCSV = (csvData: any, filename: string) => {
//   const csvBlob = new Blob([csvData], { type: 'text/csv' });
//   const csvUrl = URL.createObjectURL(csvBlob);
//   const link = document.createElement('a');
//   link.href = csvUrl;
//   link.download = filename;
//   link.click();
// };
