import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { MdKeyboardArrowRight } from 'react-icons/md';

interface IPagination {
  totalPages?: number;
  updatePageNumber: (page: number) => void;
  activePage: number;
  totalCount: number;
}

interface PaginationButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({ onClick, disabled, children }) => {
  return (
    <button className={disabled ? 'hover:cursor-not-allowed opacity-40' : 'hover:text-vibe_blue'} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

const Pagination: React.FC<IPagination> = ({ totalPages = 0, updatePageNumber, activePage = 1, totalCount }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const itemsPerPage = 10;

  useEffect(() => {
    if (totalPages && activePage > totalPages) {
      const newSearchParams = new URLSearchParams(searchParams);

      newSearchParams.set('page', totalPages.toString());

      navigate(`?${newSearchParams.toString()}`);
    }
  }, []);

  const handleFirst = () => {
    if (activePage != 1) {
      updatePageNumber(1);
    }
  };

  const handleLast = () => {
    if (activePage < totalPages) {
      updatePageNumber(totalPages);
    }
  };

  const handleNext = () => {
    if (activePage < totalPages) {
      updatePageNumber(activePage + 1);
    }
  };

  const handlePrevious = () => {
    if (activePage > 1) {
      updatePageNumber(activePage - 1);
    }
  };

  const firstItem = Math.min((activePage - 1) * itemsPerPage + 1, totalCount);
  const lastItem = Math.min(activePage * itemsPerPage, totalCount);
  const previousDisabled: boolean = activePage === 1;
  const nextDisabled: boolean = !!(activePage === totalPages || !totalPages);

  return (
    <div className="flex justify-between">
      <p>{`${firstItem}-${lastItem} of ${totalCount}`}</p>
      {totalPages > 1 && (
        <div>
          <PaginationButton onClick={handleFirst} disabled={previousDisabled}>
            <MdKeyboardDoubleArrowLeft size={'30px'} />
          </PaginationButton>
          <PaginationButton onClick={handlePrevious} disabled={previousDisabled}>
            <MdKeyboardArrowLeft size={'30px'} />
          </PaginationButton>
          <PaginationButton onClick={handleNext} disabled={nextDisabled}>
            <MdKeyboardArrowRight size={'30px'} />
          </PaginationButton>
          <PaginationButton onClick={handleLast} disabled={nextDisabled}>
            <MdKeyboardDoubleArrowRight size={'30px'} />
          </PaginationButton>
        </div>
      )}
    </div>
  );
};
export default Pagination;
