import React, { useState, useEffect, useCallback } from 'react';
import { FiX } from 'react-icons/fi';
import Select from 'react-select';
import { useGetInterests } from '../../services/interests/interest.query';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserListInterests, useEditList } from '../../services/lists/list.query';
import { getUserData } from 'utils/utils';
import { PuffLoader } from 'react-spinners';
import { twMerge } from 'tailwind-merge';
import { CiSearch } from 'react-icons/ci';
import TextField from 'components/TextField/TextField';
// import { customStyles } from 'constants/constants'

interface Interest {
  id: number;
  name: string;
}

interface SelectedInterest {
  id: number;
  label: string;
  selected: boolean;
}

// Pill component
interface PillProps {
  label: string;
  onRemove: () => void;
}
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '50px',
    height: '50px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: '50px',
    padding: '0 6px',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '50px',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};
const Pill: React.FC<PillProps> = ({ label, onRemove }) => (
  <div className="flex justify-center gap-2 items-center py-1 px-3 rounded-full bg-[#ebf0f5] cursor-pointer">
    <p className="text-[16px]">{label}</p>
    <FiX onClick={onRemove} />
  </div>
);

const EditList: React.FC = () => {
  const mockUser = getUserData();
  const { listId, listName: urlListName } = useParams<{ listId: string; listName: string }>();
  const listIdd = parseInt(listId as string);
  const navigate = useNavigate();
  const { data: userListInterestsData, isLoading: isLoadingListInterests, error: errorListInterests } = useGetUserListInterests(mockUser.user_id, listIdd);
  const { data: interestsData, isLoading: isLoadingInterests, error: errorInterests } = useGetInterests({ type: 'private' });
  const editListMutation = useEditList();

  const [selectedInterests, setSelectedInterests] = useState<SelectedInterest[]>([]);
  const [listNameEdit, setListNameEdit] = useState({ list_name: urlListName || '' });
  const [isEdit, setIsEdit] = useState(false);
  const [initialSelectedInterests, setInitialSelectedInterests] = useState<SelectedInterest[]>([]);
  const [initialListName, setInitialListName] = useState(urlListName || '');

  const hasChanges = useCallback(() => {
    if (listNameEdit.list_name !== initialListName) return true;
    if (selectedInterests.length !== initialSelectedInterests.length) return true;

    const currentIds = selectedInterests.map((interest) => interest.id);
    const initialIds = initialSelectedInterests.map((interest) => interest.id);
    currentIds.sort();
    initialIds.sort();
    console.log('names is same');

    return !currentIds.every((value, index) => value === initialIds[index]);
  }, [listNameEdit.list_name, initialListName, selectedInterests, initialSelectedInterests]);

  useEffect(() => {
    if (!isLoadingListInterests && !isLoadingInterests && userListInterestsData && interestsData) {
      const listInterestIds = new Set(userListInterestsData.interests.map((i) => i.id));
      const combinedInterests = interestsData.interests.map((interest) => ({
        id: interest.id,
        label: interest.name,
        selected: listInterestIds.has(interest.id),
      }));

      userListInterestsData.interests.forEach((interest) => {
        if (!combinedInterests.find((ci) => ci.id === interest.id)) {
          combinedInterests.push({
            id: interest.id,
            label: interest.name,
            selected: true,
          });
        }
      });

      const initialSelected = combinedInterests.filter((interest) => interest.selected);
      setSelectedInterests(initialSelected);
      setInitialSelectedInterests(initialSelected);
      setInitialListName(urlListName || '');
    }
  }, [userListInterestsData, interestsData, isLoadingListInterests, isLoadingInterests]);

  const handlePillRemove = (id: number) => {
    setSelectedInterests(selectedInterests.filter((interest) => interest.id !== id));
  };

  const handleSelectChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      if (!selectedInterests.some((interest) => interest.id === selectedOption.value)) {
        setSelectedInterests([...selectedInterests, { id: selectedOption.value, label: selectedOption.label, selected: true }]);
      }
    }
  };

  const saveChanges = () => {
    if (!userListInterestsData) {
      console.error('No user list interests data available');
      return;
    }

    const newInterestIds = selectedInterests.filter((interest) => interest.selected && !userListInterestsData.interests.some((i) => i.id === interest.id)).map((interest) => interest.id);

    const removeInterestIds = userListInterestsData.interests.filter((i) => !selectedInterests.some((interest) => interest.id === i.id && interest.selected)).map((i) => i.id);

    const payload = {
      user_id: mockUser.user_id,
      list_id: listIdd,
      list_name: listNameEdit.list_name,
      new_interest_ids: newInterestIds,
      remove_interest_ids: removeInterestIds,
    };

    editListMutation.mutate(payload, {
      onSuccess: () => {
        navigate('/list');
      },
      onError: (error) => {
        console.error('Failed to edit list', error);
      },
    });
  };

  if (isLoadingInterests || isLoadingListInterests) return <PuffLoader color="#195CE5" className="m-auto" />;
  if (errorInterests || errorListInterests) return <div>Error loading data</div>;

  const selectedInterestsSet = new Set(selectedInterests.map((interest) => interest.id));
  const interestOptions = interestsData
    ? interestsData.interests
        .map((interest) => {
          if (selectedInterestsSet.has(interest.id)) return null;
          return { value: interest.id, label: interest.name };
        })
        .filter(Boolean)
    : [];

  return (
    <div className="flex flex-col justify-between min-h-[81svh] max-w-[400px] m-auto md:min-h-[75svh] px-4">
      <div className="flex flex-col pt-4 ">
        <div className="mt-4 flex items-center justify-between break-all">
          <TextField label="List Name" placeholder="Enter list name" value={listNameEdit.list_name} onChange={setListNameEdit} field="list_name" required={true} edit={isEdit} setEdit={setIsEdit} />
        </div>
        <p className="text-[#052B33] font-semibold leading-[22.5px] text-[20px] mt-2">Interests</p>
        <Select options={interestOptions} onChange={handleSelectChange} value={null} placeholder="Select interests" className="mt-3" styles={customStyles} />
        <div className="mt-4 flex flex-wrap gap-2">
          {selectedInterests.map((interest) => (
            <Pill key={interest.id} label={interest.label} onRemove={() => handlePillRemove(interest.id)} />
          ))}
        </div>
      </div>
      <button
        disabled={editListMutation.isPending || !listNameEdit.list_name || !hasChanges() || selectedInterests.length === 0}
        onClick={saveChanges}
        className={twMerge(
          'bg-vibe_blue py-3 my-3 text-white text-1xl font-semibold rounded-lg flex flex-row justify-center items-center w-full max-w-[368px] mx-auto',
          (!listNameEdit.list_name || !hasChanges() || selectedInterests.length === 0) && 'opacity-60'
        )}
      >
        <PuffLoader color="white" loading={editListMutation.isPending} size={25} />
        Save
      </button>
    </div>
  );
};

export default EditList;
