import React, { useMemo } from 'react';
import { useTable, CellProps, HeaderProps } from 'react-table';
import moment from 'moment';

import { Action, CellData } from 'types/common';
import { CELL_TYPE, BUTTON_TYPES, leadStatusDict, imgLink } from 'constants/constants';

import { FaCheckSquare } from 'react-icons/fa';
import { ICell, INode } from 'types/table';
import { twMerge } from 'tailwind-merge';

const CustomCell: React.FC<ICell> = ({ value, cellType, className }) => {
  switch (cellType) {
    case CELL_TYPE.TEXT:
      return <p className={twMerge(' text-ellipsis overflow-hidden whitespace-nowrap max-w-[150px]', className)}>{value}</p>;
    case CELL_TYPE.COLORFUL_TEXT:
      return (
        <p
          className={twMerge(
            `text-ellipsis overflow-hidden whitespace-nowrap max-w-[500px] ${
              leadStatusDict[value] === 'Awaiting reply' || leadStatusDict[value] === 'In conversation'
                ? 'text-suspend'
                : leadStatusDict[value] === 'Not Interested'
                ? 'text-delete'
                : 'text-interested'
            }`,
            className
          )}
        >
          {leadStatusDict[value]}
        </p>
      );
    case CELL_TYPE.DROPDOWN:
      return (
        <div>
          <select value={leadStatusDict[value.label]} onChange={value.onChange} id={value.id} className={twMerge('border border-primary rounded-md px-1 h-6 w-40 focus:outline-primary', className)}>
            {value.options.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      );
    case CELL_TYPE.IMAGE:
      return (
        <div>
          <img
            src={value || imgLink}
            alt="logo"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = imgLink;
            }}
            className={twMerge(' w-[33px] h-[33px] rounded-full', className)}
          />
        </div>
      );
    case CELL_TYPE.DATE:
      return <p>{moment(value).format('DD-MM-YYYY')}</p>;
    case CELL_TYPE.LIST:
      return (
        <div className={twMerge('flex gap-3', className)}>
          {(value as string[]).slice(0, 3).map((item, index) => (
            <span key={index} className="px-3 py-1 border border-primary rounded-xl shadow-sm shadow-grey-200 max-w-40 whitespace-nowrap text-ellipsis overflow-hidden">
              {item}
            </span>
          ))}
          {(value as string[]).length > 3 && (
            <span className="px-3 py-1 border border-primary rounded-xl shadow-sm shadow-grey-200 whitespace-nowrap text-ellipsis overflow-hidden">+{value.length - 3}</span>
          )}
        </div>
      );
    case CELL_TYPE.BUTTONS:
      return (
        <>
          <div className={twMerge('flex gap-3', className)}>
            {(value as Action[]).map((value, index) => (
              <button onClick={value.handleOnClick} key={index}>
                {BUTTON_TYPES[value.type as keyof typeof BUTTON_TYPES].icon as JSX.Element}
              </button>
            ))}
          </div>
        </>
      );
    default:
      return <p></p>;
  }
};

const CustomHeader: React.FC<{ text?: string }> = ({ text }) => {
  return <p>{text as string}</p>;
};

const CustomTable: React.FC<{ nodes: INode[] }> = ({ nodes }) => {
  const memoizedData = useMemo(() => nodes, [nodes]);

  const columns = useMemo(() => {
    if (nodes.length === 0) return [];
    return Object.entries(nodes[0])
      .filter(([key]) => !(key === 'id' || key === 'status'))
      .map(([key, data]) => ({
        Header: ({}: HeaderProps<any>) => <CustomHeader text={key} />,
        accessor: `${key}.value`,
        Cell: ({ cell: { value } }: CellProps<any>) => <CustomCell value={value} cellType={(data as CellData).cellType} className={(data as CellData).className} />,
      }));
  }, [nodes]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: memoizedData,
  });

  return (
    <div className="max-w-screen overflow-x-scroll border  border-[#E1E1E1]">
      <table {...getTableProps()} className="min-w-full">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  key={index}
                  style={{
                    borderBottom: '1px solid #E1E1E1',
                    height: '40px',
                    color: '#195CE5',
                    padding: '0 20px',
                    textAlign: 'start',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={index}
                style={{
                  height: '40px',
                  // border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              >
                {row.cells.map((cell, index) => (
                  <td
                    {...cell.getCellProps()}
                    key={index}
                    style={{
                      borderBottom: '1px solid #E1E1E1',
                      height: '51px',
                      color: '#030303',
                      padding: '0 20px',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
