import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetSingleUser } from 'services/user/user.query';
import { useGetInterests } from 'services/interests/interest.query';
import { useGetCountries } from 'services/countries/countries.query';
import { PuffLoader } from 'react-spinners';
import ProfileDetailsEditor from './ProfileDetailsEditor';

type Item = {
  label: string;
  value: string;
};

const UserProfileView = ({ userId }: { userId: number }) => {
  const { data, isFetching, isError } = useGetSingleUser({ userId });
  const { data: publicInterests, isLoading } = useGetInterests({ type: 'public', userId });
  const { data: countries, isLoading: countriesLoading } = useGetCountries();

  // const [location, setLocation] = useState();
  const [values, setValues] = useState({
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    biography: data?.biography || '',
    location: data?.location || { label: '', value: '' },
    username: data?.username || '',
  });
  console.log('reeff');

  useEffect(() => {
    if (data) {
      setValues((prevValues) => ({
        ...prevValues,
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        biography: data?.biography || '',
        location: countries?.find((country: Item) => country.label === data?.location) || { label: '', value: '' },
        username: data?.username || '',
      }));
    }
  }, [data, countries]);

  if (isFetching || !values) {
    return (
      <div className=" flex justify-center">
        <PuffLoader size={25} color="#00CED1" />
      </div>
    );
  }

  if (isError) return <p className="text-red-500">An error occured while fetching the data, Please try again later!</p>;

  return (
    <div>
      <ProfileDetailsEditor
        profileUpdatePending={false}
        setValues={() => {}}
        countries={[]}
        countriesLoading={countriesLoading}
        isLoading={isLoading}
        publicInterests={publicInterests}
        setEdit={() => {}}
        handleSave={() => {}}
        hanldeUpload={() => {}}
        edit={false}
        imagePreview={data?.image}
        values={values}
        email={data?.email || ''}
        location={data?.location}
        readOnly={true}
      />
    </div>
  );
};

const ProfileView = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  if (userId === undefined) {
    navigate('/');
    return null;
  }

  const userIdNumber = userId ? Number.parseInt(userId, 10) : NaN;

  return userIdNumber ? <UserProfileView userId={userIdNumber} /> : null;
};
export default ProfileView;
