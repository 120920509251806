import { ITextField } from 'types/component';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { useState } from 'react';

const TextField = ({ label, placeholder, type, onChange, field, value, required, edit, setEdit, readOnly = false }: ITextField) => {
  const setValues = (value: string) => {
    if (!edit) setEdit && setEdit(true);
    onChange((prev: { [key: string]: string }) => ({ ...prev, [field]: value }));
  };
  const [password, showPassword] = useState(false);
  return (
    <div className="flex flex-col gap-1.5 my-1 w-full">
      <label htmlFor="text" className="text-[14px] text-[#052B33]">
        {label}
      </label>
      <div className="relative">
        <input
          type={type && !password ? type : 'text'}
          placeholder={placeholder}
          onChange={(e) => {
            if (readOnly) return;
            setValues(e.target.value);
          }}
          required={required}
          value={value}
          readOnly={readOnly}
          disabled={readOnly}
          className="h-14 w-full pl-4 bg-[#F7F6F9] text-[16px] font-medium text-[#052B33] pr-4 rounded-md border border-gray-200 focus:border-gray-300 focus:outline-none"
        />
        {type === 'password' && value?.length && value.length > 0 ? (
          <>
            {password && <FiEye onClick={() => showPassword(!password)} height={15} width={17} className="absolute right-4 top-5 text-[#052B33]" />}
            {!password && <FiEyeOff onClick={() => showPassword(!password)} height={15} width={17} className="absolute right-4 top-5 text-[#052B33]" />}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TextField;
