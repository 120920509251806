import TextField from 'components/TextField/TextField';
import { useState, useEffect } from 'react';
import { useUpdatePassword, useResetPasswordVerification } from 'services/user/user.query';
import { useSearchParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PuffLoader } from 'react-spinners';
const UpdatePassword: React.FC = () => {
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const navigate = useNavigate();
  const { isLoading, error } = useResetPasswordVerification(token);
  const updatePasswordMutation = useUpdatePassword();
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (values.password !== values.confirmPassword) {
      setPasswordError(true);
      return;
    }
    updatePasswordMutation.mutate({ token, new_password: values.password });
  };

  if (error) {
    toast.error('Failed to reset password. Please try again.');
    navigate('/forgot-password');
  }

  return (
    <div className="max-w-[400px] mx-auto">
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-gray-900 bg-opacity-50">
          <PuffLoader color="#fff" size={100} />
        </div>
      )}
      <form onSubmit={handleSubmit} className="flex flex-col justify-center gap-7 h-[100svh] mx-4">
        <div className="mt-14">
          <div className="flex justify-start mb-8">
            <h1 className="text-3xl font-bold text-[#052B33] w-full text-center">Update Password</h1>
          </div>
          <div className="flex flex-col items-center gap-4">
            <TextField label="Password" placeholder="Enter your password" onChange={setValues} type="password" field="password" required={true} value={values.password} />
            <TextField label="Password" placeholder="Confirm password" onChange={setValues} type="password" field="confirmPassword" required={true} value={values.confirmPassword} />
            {passwordError && <p className="text-red-500 text-[16px] font-semibold">Passwords do not match</p>}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mb-10 gap-4">
          <button className="flex justify-center items-center w-full bg-blue-600 rounded-md text-center text-white py-3 font-semibold gap-3">Update</button>
        </div>
      </form>
    </div>
  );
};
export default UpdatePassword;
