import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const AuthRedirect = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, []);
  return <div>{children}</div>;
};

export default AuthRedirect;
