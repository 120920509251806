import { useQuery } from '@tanstack/react-query';
import { fetchCountryList } from './countries.service';

const useGetCountries = () => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: fetchCountryList,
  });
};

export { useGetCountries };
