import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { updateInterest, getInterests, clearInterests } from './interest.service';
import { IInterestPayload } from 'types/services';

const useGetInterests = (payload: IInterestPayload) => {
  return useQuery({
    queryKey: ['interests', payload],
    queryFn: () => getInterests(payload),
  });
};

const useAddInterest = (payload: IInterestPayload) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateInterest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['interests', payload] });
    },
  });
};

const useClearInterest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: clearInterests,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['interests'] });
    },
  });
};

export { useGetInterests, useAddInterest, useClearInterest };
