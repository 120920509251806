import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from 'pages/Home/Home';
import { TabNavigation } from 'hoc/TabNavigation/tabNavigation';
import Lists from 'pages/Lists/Lists';
import Profile from 'pages/Profile/Profile';
import AddInterest from 'pages/AddInterests/AddInterest';
import StartVibeCheck from 'pages/Home/StartVibeCheck/StartVibeCheck';
import JoinVibeCheck from 'pages/Home/JoinVibeCheck/JoinVibeCheck';
import JoinByQr from 'pages/Home/JoinVibeCheck/JoinbyQr';
import ScanQR from 'pages/Home/JoinVibeCheck/ScanQR/ScanQR';
import ProfileView from 'pages/Profile/EditProfile/ProfileView';

import EditList from 'pages/Lists/EditList';
import Users from 'pages/Users/Users';
import VibeMatch from 'pages/Home/VibeMatch/VibeMatch';
import JoinByOtp from 'pages/Home/JoinVibeCheck/JoinByOtp';
import Login from 'pages/Login/Login';
import Signup from 'pages/Signup/Signup';
import ProtectedRoutes from 'components/ProtectedRoutes/ProtectedRoutes';
import AdminProtectedRoutes from 'components/ProtectedRoutes/AdminProtectedRoutes';
import AuthRedirect from 'components/AuthRedirect/AuthRedirect';
import AddList from 'pages/Lists/AddList';
import EditProfile from 'pages/Profile/EditProfile/EditProfile';
import Interests from 'pages/Home/Interests/Interests';
import InterestDetails from 'pages/Home/Interests/InterestDetails';
import { HeaderLayout } from 'components/HeaderLayout';
import { LoginRedirect } from 'components/LoginRedirect';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Navbar from 'components/Navbar/Navbar';
import UpdatePassword from 'pages/UpdatePassword/UpdatePassword';
import ActivationPage from 'pages/ActivationPage/ActivationPage';
import VerifyEmail from 'pages/VerifyEmail/VerifyEmail';
import SignupConfirmation from 'pages/SignupConfirmation/SignupConfirmation';
import ForgetPasswordConfirmation from 'pages/ForgotPassword/ForgetPasswordConfirmation';

function App() {
  return (
    <div className="App">
      <div className="md:hidden">
        <TabNavigation />
      </div>
      <div className="hidden md:block">
        <Navbar />
      </div>

      <Routes>
        <Route element={<HeaderLayout />}>
          <Route
            path="/users"
            element={
              <AdminProtectedRoutes>
                <Users />
              </AdminProtectedRoutes>
            }
          />
          <Route
            path="/view-user/:userId"
            element={
              <AdminProtectedRoutes>
                <ProfileView />
              </AdminProtectedRoutes>
            }
          />
          <Route path="/home">
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <Home />
                </ProtectedRoutes>
              }
            />
            <Route path="interests" element={<Interests />} />
            <Route path="interests/category" element={<InterestDetails />} />
            <Route
              path="start-vibe-check"
              element={
                <ProtectedRoutes>
                  <StartVibeCheck />
                </ProtectedRoutes>
              }
            />
            <Route path="join-vibe-check">
              <Route
                path=""
                element={
                  <ProtectedRoutes>
                    <JoinVibeCheck />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="scan-qr"
                element={
                  <ProtectedRoutes>
                    <ScanQR />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="join-otp"
                element={
                  <ProtectedRoutes>
                    <JoinByOtp />
                  </ProtectedRoutes>
                }
              />
            </Route>
            <Route
              path="join-qr"
              element={
                <ProtectedRoutes>
                  <JoinByQr />
                </ProtectedRoutes>
              }
            />
            <Route path="vibe-match">
              <Route
                path=""
                element={
                  <ProtectedRoutes>
                    <VibeMatch />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="interests"
                element={
                  <ProtectedRoutes>
                    <Interests />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="interests/:id"
                element={
                  <ProtectedRoutes>
                    <InterestDetails />
                  </ProtectedRoutes>
                }
              />
            </Route>
          </Route>

          <Route path="/list">
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <Lists />
                </ProtectedRoutes>
              }
            />
            <Route
              path=":listId/:listName"
              element={
                <ProtectedRoutes>
                  <EditList />
                </ProtectedRoutes>
              }
            />
            <Route path="add-list" element={<AddList />} />
          </Route>

          <Route path="/profile">
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <Profile />
                </ProtectedRoutes>
              }
            />
            <Route path="edit">
              <Route
                path=""
                element={
                  <ProtectedRoutes>
                    <EditProfile />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="add-interest"
                element={
                  <ProtectedRoutes>
                    <AddInterest type="public" />
                  </ProtectedRoutes>
                }
              />
            </Route>
          </Route>

          <Route path="/add-interest">
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <AddInterest type="private" />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path="/activate-account"
            element={
              <ProtectedRoutes>
                <ActivationPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path="/login">
          <Route
            path=""
            element={
              <AuthRedirect>
                <Login />
              </AuthRedirect>
            }
          />
        </Route>
        <Route path="/forgot-password">
          <Route
            path=""
            element={
              <AuthRedirect>
                <ForgotPassword />
              </AuthRedirect>
            }
          />
          <Route
            path="confirmation/:email"
            element={
              <AuthRedirect>
                <ForgetPasswordConfirmation />
              </AuthRedirect>
            }
          />
        </Route>

        <Route path="/verify-email" element={<VerifyEmail />} />

        <Route path="/reset-password-verification">
          <Route
            path=""
            element={
              <AuthRedirect>
                <UpdatePassword />
              </AuthRedirect>
            }
          />
        </Route>
        <Route path="/sign-up">
          <Route
            path=""
            element={
              <AuthRedirect>
                <Signup />
              </AuthRedirect>
            }
          />
          <Route
            path="confirmation"
            element={
              <AuthRedirect>
                <SignupConfirmation />
              </AuthRedirect>
            }
          />
        </Route>

        <Route
          path="/"
          element={
            <AuthRedirect>
              <LoginRedirect />
            </AuthRedirect>
          }
        />
        <Route
          path="/*"
          element={
            <ProtectedRoutes>
              <Navigate to={'/home'} />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
