import { ProfileCard } from 'components/ui/ProfileCard';
import { useNavigate } from 'react-router-dom';
import { useGetInterests } from 'services/interests/interest.query';
import { PuffLoader } from 'react-spinners';
import { getUserData } from 'utils/utils';
import { useCreateVibeSession } from 'services/vibe_check/vibe.query';
import { imgLink } from 'constants/constants';

export const Home: React.FC = () => {
  const user = getUserData();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateVibeSession();
  const { data, isLoading, isSuccess, isError } = useGetInterests({
    type: 'public',
  });

  if (isLoading) return <PuffLoader color="#195CE5" className=" m-auto" />;
  if (isError)
    return (
      <div>
        <h1 className=" text-center font-bold text-red-500 text-xl">An Error Ocurred Loading User!</h1>
      </div>
    );
  return (
    <div className="max-w-[600px] mx-auto">
      <div className="px-3">
        <div className="flex flex-col items-center max-w-[420px] mx-auto">
          <ProfileCard name={user?.first_name} greetings="How are you feeling today?" image={user.image || imgLink} />
          <h1 className="text-[16px] leading-[22.4px] text-center md:w-[90%] md:text-[#7D7D7D] w-[75%] m-auto md:my-0 md:mb-8 my-4">
            Mindfulness and self-care are keys to a healthy life. Start your day with a vibe check
          </h1>
          <button className=" bg-vibe_blue py-4 mb-3  text-white text-1xl font-semibold rounded-lg w-full flex justify-center items-center gap-2" onClick={() => navigate('/home/start-vibe-check')}>
            <PuffLoader color="white" size={30} loading={isPending} />
            Start Vibe Check
          </button>
          <button className=" py-3 border border-black text-1xl font-semibold rounded-lg w-full " onClick={() => navigate('/home/join-vibe-check')}>
            Join Vibe Check
          </button>
        </div>
        <h1 className="text-start font-bold md:mt-10 mt-5 mb-3">Public Interests</h1>
        <div className="flex flex-wrap gap-2 gap-x-3 gap-y-2 mb-2">
          {data?.interests.map((interest) => (
            <p className="bg-gray-100 px-4 py-1 rounded-3xl" key={interest.id}>
              {' '}
              {interest.name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
