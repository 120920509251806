import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getLists, getUserListInterests, editList } from './list.service';
import { IUser } from 'types/common';
import { addList } from './list.service';
import { IAddListPayload, IAddListResponse, IEditListPayload } from 'types/services';
import { toast } from 'react-toastify';
const useAddList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['addList'],
    mutationFn: addList,
    onError: (error) => {
      //@ts-ignore
      toast.error(error?.response?.data?.detail || 'Error occured while adding list');
    },
  });
};

const useGetLists = (user: IUser) => {
  return useQuery({
    queryKey: ['lists', user.user_id],
    queryFn: () => getLists(user.user_id),
  });
};

const useGetUserListInterests = (user_id: number, list_id: number) => {
  return useQuery({ queryKey: ['userListInterests', user_id, list_id], queryFn: () => getUserListInterests(user_id, list_id) });
};

export const useEditList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['editList'],
    mutationFn: editList,
    onError: (error) => {
      //@ts-ignore
      toast.error(error?.response?.data?.detail || 'Error occured while editing list');
    },
  });
};

export { useGetLists, useAddList, useGetUserListInterests };
