import { CiSearch } from 'react-icons/ci';
// import image form assets
import work from '../../assets/work1.jpg';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { FiPlusCircle } from 'react-icons/fi';
import { useGetLists } from '../../services/lists/list.query';
import { IUser } from '../../types/common';
import { getUserData } from 'utils/utils';
import { PuffLoader } from 'react-spinners';
import { useState } from 'react';
import waveHandSVG from 'assets/wave.png';

const Lists: React.FC = () => {
  const mockUser = getUserData();
  const { data: lists, isLoading, isError } = useGetLists(mockUser);
  const [searchTerm, setSearchTerm] = useState('');
  if (isLoading) return <PuffLoader color="#195CE5" className=" m-auto" />;
  const filteredLists = lists?.filter((list) => list.list_name.toLowerCase().includes(searchTerm.toLowerCase()));
  if (isError)
    return (
      <div>
        <h1 className=" text-center font-bold text-red-500 text-xl">An Error Ocurred Loading Interests!</h1>
      </div>
    );
  return (
    <div className="flex flex-col pt-4 px-4  max-w-[800px] m-auto mb-20 ">
      <h1 className="text-4xl font-bold text-start capitalize">
        Hi, {mockUser.first_name}{' '}
        <span>
          <img src={waveHandSVG} alt="Wave" className="inline-block h-8 w-8 ml-0 mr-1 mb-3" />
        </span>
      </h1>
      <div className="relative mt-4 ">
        <CiSearch className="absolute left-3 top-4 text-gray-500 text-2xl" />

        <input
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full h-14 pl-10 bg-gray-100 text-md pr-12 rounded-md border-2 border-gray-200 focus:border-gray-300 focus:outline-none"
        />
        <div className="mt-4 flex items-center justify-end break-all">
          <Link to="add-list">
            <button className="flex items-center text-blue-700 border border-blue-700 px-2 py-2 rounded-md text-xs" style={{ border: '2px solid', fontWeight: 'bold' }}>
              <FiPlusCircle className="mr-1" style={{ fontSize: '20px' }} />
              Add New List
            </button>
          </Link>
        </div>
        <div className=" md:grid md:grid-cols-2 md:gap-8">
          {filteredLists &&
            filteredLists.map((list) => (
              <Link key={list.list_id} to={`/list/${list.list_id}/${encodeURIComponent(list?.list_name || ' ')}`} className="relative rounded-2xl block shadow-md mt-4 select-none cursor-pointer">
                <img src={work} alt={list.list_name} className="w-full h-40 md:h-52 object-cover rounded-2xl" />
                <div className="absolute bottom-0 left-0 w-full bg-opacity-50 p-3 flex justify-between">
                  <h1 className="text-2xl font-bold text-white whitespace-nowrap overflow-hidden max-w-[80%] text-ellipsis">{list?.list_name || ' '}</h1>
                  <IoArrowForwardCircleOutline className="text-3xl text-white" />
                </div>
              </Link>
            ))}
        </div>

        {/* </div> */}
      </div>
    </div>
  );
};

export default Lists;
