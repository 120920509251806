import { useNavigate } from 'react-router-dom';

import ChevronRightIcon from 'assets/ChevronRight.svg';
import MusictIcon from 'assets/music.svg';
import OutdoorIcon from 'assets/outdoor.svg';
import PizzaIcon from 'assets/pizza.svg';
import SportIcon from 'assets/sports.svg';
import TennisIcon from 'assets/tennis.svg';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface Category {
  sub_categories: Record<string, string[]>;
  match_rate: number;
}

interface IVibeResult {
  [key: string]: Category;
}

interface VibeMatchProps {
  // icon: string;
  interestType: string;
  matchPercentage: number;
  sub_categories: ResultSubCategory;
}

export interface ResultCategory {
  match_rate: number;
  sub_categories: ResultSubCategory;
}
export type ResultSubCategory = Record<string, string[]>;

const ALL_INTERESTS = [
  {
    id: 1,
    icon: MusictIcon,
    interestType: 'Annie',
    matchPercentage: 45,
  },
  {
    id: 2,
    icon: OutdoorIcon,
    interestType: 'Annie',
    matchPercentage: 45,
  },
  {
    id: 3,
    icon: SportIcon,
    interestType: 'Annie',
    matchPercentage: 45,
  },
];

const InterestMatch: React.FC<VibeMatchProps> = ({ interestType, matchPercentage, sub_categories }) => {
  const navigate = useNavigate();
  const allowClick = !!matchPercentage && Object.keys(sub_categories).length > 0;
  return (
    <div>
      <div
        className={twMerge('flex items-center gap-4 rounded-2xl bg-[#F7F9FE] p-5 border border-[#CBDFFF] shadow-md', allowClick && 'cursor-pointer active:bg-[#e5ebfa]')}
        onClick={() => {
          console.log('====>allowClick', allowClick, matchPercentage, !!matchPercentage, Object.keys(sub_categories).length > 0);
          if (allowClick) {
            navigate('category', { state: { resultCatagory: { interestType, matchPercentage, sub_categories } } });
          }
        }}
      >
        {/* <div className='bg-[#CBDFFF] rounded-2xl'>
                    <img src={icon} className='m-auto p-4' alt='Icon' />

                </div> */}
        <div className="text-start flex flex-col gap-1">
          <p className="font-bold text-xl">{interestType}</p>
          <p className="text-[#637087] text-sm">Match {matchPercentage}%</p>
        </div>
        <img src={ChevronRightIcon} className="ms-auto" alt="ChevronRight" />
      </div>
    </div>
  );
};

const Interests = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const vibeResult: IVibeResult | undefined = state?.vibeReesult;
  console.log('=====>', vibeResult);

  if (!vibeResult) return <h1 className=" text-center"> Result of this Vibe does not exist</h1>;

  console.log(vibeResult);
  return (
    <div className="flex flex-col gap-4 p-4 max-w-[800px] m-auto">
      {Object.entries(vibeResult).map(([key, value]) => (
        <InterestMatch key={key} interestType={key} matchPercentage={value.match_rate} sub_categories={value.sub_categories} />
      ))}
    </div>
  );
};
export default Interests;
