import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';

import { ReactComponent as UploadIcon } from 'assets/pictureUpload.svg';
import TextField from 'components/TextField/TextField';
import { customStyles, imgLink } from 'constants/constants';
import { ProfileCard } from 'components/ui/ProfileCard';
import React from 'react';

interface UserProfileValues {
  first_name: string;
  last_name: string;
  biography: string;
  location: { label: string; value: string } | string;
  username: string;
}

interface ProfileDetailsEditorProps {
  profileUpdatePending: boolean;
  setValues: React.Dispatch<React.SetStateAction<UserProfileValues>>;
  countries: string[];
  countriesLoading: boolean;
  isLoading: boolean;
  publicInterests?: { interests: { name: string }[] } | undefined;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: () => void;
  hanldeUpload: () => void;
  edit: boolean;
  readOnly?: boolean;
  imagePreview: string;
  values: UserProfileValues;
  email: string;
  location: string;
}

const ProfileDetailsEditor: React.FC<ProfileDetailsEditorProps> = ({
  profileUpdatePending,
  setValues,
  countries,
  countriesLoading,
  isLoading,
  publicInterests,
  setEdit,
  handleSave,
  hanldeUpload,
  edit,
  imagePreview,
  values,
  email,
  location,
  readOnly = false,
}) => {
  const navigate = useNavigate();
  console.log('location', location, values.location);
  return (
    <>
      <div className="md:max-w-[800px] md:mx-auto">
        {profileUpdatePending && (
          <div className="fixed md:hidden top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-gray-900 bg-opacity-50">
            <PuffLoader color="#fff" size={100} />
          </div>
        )}
        <div>
          {edit && (
            <button className="fixed md:hidden right-5 top-5 z-10" onClick={() => handleSave()}>
              <span className="text-[#195CE5] text-[16px] leading-[20.5px] font-medium">Save</span>
            </button>
          )}
          <div className="flex flex-col justify-center mx-4 gap-3 my-4">
            <div className="relative md:hidden">
              {imagePreview ? (
                <img src={imagePreview} alt="preview" className="object-cover h-[209px] flex rounded-lg w-full" />
              ) : (
                <div className="flex flex-col gap-3 justify-center items-center bg-[#F7F6F9] rounded-xl w-full h-[209px]" onClick={() => hanldeUpload()}>
                  <img src={imgLink} alt="profile" className="w-25 h-25 rounded-full" />
                  <p className="text-[14px] text-[#828282]">Touch to upload a picture</p>
                </div>
              )}
              {!readOnly && (
                <button className="absolute bottom-2 right-2" onClick={() => hanldeUpload()}>
                  {imagePreview && <UploadIcon className="fill-white" />}
                </button>
              )}
            </div>
            <div className="hidden md:block">
              <ProfileCard
                name={values.first_name}
                //greetings="How are you feeling today?"
                image={imagePreview || imgLink}
                email={email}
                type="profile"
                classForName=" text-[20px] leading-[22.5px] font-medium"
                location={location}
                imageContainer="relative"
                buttonClick={hanldeUpload}
                edit={!readOnly}
                containerClass={'md:justify-start'}
              />
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 md:items-start md:gap-6 gap-2 items-center w-full">
              <TextField
                label="First Name"
                placeholder="Enter first name"
                field="first_name"
                required={true}
                value={values.first_name}
                onChange={setValues}
                edit={edit}
                setEdit={setEdit}
                readOnly={readOnly}
              />
              <TextField
                label="Last Name"
                placeholder="Enter last name"
                field="last_name"
                required={true}
                value={values.last_name}
                onChange={setValues}
                edit={edit}
                setEdit={setEdit}
                readOnly={readOnly}
              />
              <TextField
                label="Username"
                placeholder="Enter username"
                field="username"
                required={true}
                value={values.username}
                onChange={setValues}
                edit={edit}
                setEdit={setEdit}
                readOnly={readOnly}
              />
              <div className="flex flex-col gap-1.5 my-1 w-full">
                <label htmlFor="text" className="text-[14px] text-[#052B33]">
                  Location
                </label>
                {typeof values?.location === 'object' && values?.location?.label ? (
                  <Select
                    placeholder="Select your location"
                    options={countries}
                    name="location"
                    value={values.location}
                    onChange={(selectedOption) => {
                      if (!edit) setEdit(true);
                      //@ts-ignore
                      setValues({ ...values, location: selectedOption });
                    }}
                    styles={customStyles}
                    isLoading={countriesLoading}
                    isDisabled={readOnly}
                  />
                ) : (
                  <Select
                    placeholder="Select your location"
                    options={countries}
                    name="location"
                    onChange={(selectedOption) => {
                      if (!edit) setEdit(true);
                      //@ts-ignore
                      setValues({ ...values, location: selectedOption });
                    }}
                    styles={customStyles}
                    isLoading={countriesLoading}
                    isDisabled={readOnly}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1.5 my-1 w-full">
              <label htmlFor="text" className="text-[14px] text-[#052B33]">
                Biography
              </label>
              <textarea
                placeholder="Enter your biography"
                name="biography"
                onChange={(e) => {
                  if (!edit) setEdit(true);
                  setValues({ ...values, biography: e.target.value });
                }}
                required={true}
                value={values.biography}
                disabled={readOnly}
                readOnly={readOnly}
                className=" w-full pl-4 py-4 bg-[#F7F6F9] text-[16px] font-medium text-[#052B33] pr-4 rounded-md border border-gray-200 focus:border-gray-300 focus:outline-none h-[135px] select-text"
              />
            </div>
            <div className="flex justify-between items-center w-full mt-2">
              <h3 className="text-[18px] text-[#052B33] leading-[20px] font-semibold">Public Interests</h3>
              {!readOnly && (
                <button className="flex items-center justify-center border border-[#195CE5] px-7 py-1.5 rounded-full" onClick={() => navigate('/profile/edit/add-interest')}>
                  <span className="text-[#195CE5] text-[15px] leading-[17.5px]">Add</span>
                </button>
              )}
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center mt-5">
                <PuffLoader color="#195CE5" />
              </div>
            ) : (
              <div className="flex justify-start flex-wrap gap-2.5 mb-3">
                {publicInterests?.interests?.map((interest, index) => (
                  <div key={index} className="flex justify-center gap-2 items-center bg-[#EBF0F5] py-2 px-3.5 rounded-full">
                    <p className="text-[#052B33] text-[16px]">{interest.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          {edit && !readOnly && (
            <button
              className={`hidden md:block w-[98%] max-w-[368px] rounded-[10px] bg-[#195CE5] active:bg-[#3a5997] mx-auto py-3  mb-2 text-lg text-white font-bold  items-center justify-center gap-2
                              ${profileUpdatePending && ' opacity-60'}`}
              disabled={profileUpdatePending}
              onClick={handleSave}
            >
              <PuffLoader color="white" size={30} loading={profileUpdatePending} />
              Save
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileDetailsEditor;
