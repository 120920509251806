import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';

import { IOTP } from 'types/component';
import { OTP } from 'components/OTP/OTP';
import { useJoinVibeSession } from 'services/vibe_check/vibe.query';
import { getUserData } from 'utils/utils';

export const JoinByOtp: React.FC<IOTP> = ({ value, onChange, numInputs, renderSeparator, renderInput, skipDefaultStyles, inputStyle, disabled, shouldAutoFocus, ...rest }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedList = state?.selectedList;
  const [otp, setOtp] = useState('');
  const { mutate, isPending } = useJoinVibeSession();
  const { user_id } = getUserData();

  const handleJoinSession = () => {
    if (!isPending) {
      mutate({ code: otp, user_id, list_id: selectedList });
    }
  };

  useEffect(() => {
    if (!selectedList) {
      navigate('/home/join-vibe-check');
    }
  }, []);

  if (!selectedList) {
    return <></>;
  }

  return (
    <div className="h-[48svh] px-5 flex flex-col justify-between max-w-[600px] m-auto">
      <div className="pt-10">
        <h1 className=" font-bold text-xl text-center">Enter code</h1>
        <OTP shouldAutoFocus={true} inputType="number" value={otp} onChange={setOtp} />
      </div>
      <button
        className={`bg-vibe_blue py-4 mb-4 active:bg-[#3a5997] text-white text-xl font-semibold rounded-lg w-full inline-flex items-center justify-center gap-2
                ${isPending && 'bg-[#3a5997]'}`}
        onClick={handleJoinSession}
        disabled={otp.length !== 6 || isPending}
      >
        <PuffLoader color="white" size={30} loading={isPending} />
        Submit
      </button>
    </div>
  );
};

export default JoinByOtp;
