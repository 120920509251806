import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import QRCode from 'react-qr-code';

import 'react-tabs/style/react-tabs.css';
import './StartVibeCheck.css';

import CopyComponent from 'components/functional/Copy';
import { ProfileCard } from 'components/ui/ProfileCard';
import SelectList from 'components/ui/SelectList';
import { OTP } from 'components/OTP/OTP';
import { IVibeCheck } from 'types/common';
import { IListResponse } from 'types/services';
import { getUserData } from 'utils/utils';
import { useCreateVibeSession } from 'services/vibe_check/vibe.query';
import VibeMatch from '../VibeMatch/VibeMatch';

const imgLink = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIZ2XKQppqMkYTkcL_TE4pIYvQb_yS0tphX7WzJJBtVQ&s';

const StartByCodeOrQR = ({ vibeCheck }: { vibeCheck?: IVibeCheck }) => {
  const navigate = useNavigate();
  const user = getUserData();
  return (
    <div className="flex flex-col items-center">
      <ProfileCard name={user.first_name} type="profile" email={user.email} image={user.image || imgLink} containerClass="bg-white pb-2 pt-0" />
      <h1 className="text-[16px] leading-[22.4px] text-center w-[100%] m-auto mb-1">Your friends can join your vibe check using this code or scanning the QR code</h1>
      <Tabs className="w-full mb-2.5">
        <TabList className="my-2 flex justify-center">
          <Tab>Code</Tab>
          <Tab>QR</Tab>
        </TabList>

        <TabPanel>
          <div className="border border-blue-500 w-full rounded-xl py-3 min-h-52">
            <h1 className="text-[14px] leading-[17.4px] text-start m-auto mb-6 px-5">Your friends can join your vibe check using this code</h1>

            <OTP value={vibeCheck?.code?.toString()} disabled />
            <CopyComponent text={vibeCheck?.code?.toString()} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="border border-blue-500 w-full rounded-xl py-3 h-52 flex flex-col items-center">
            <h1 className="text-[14px] leading-[17.4px] text-start mb-5 px-5">Your friends can join your vibe check using this code</h1>

            <div className="h-fit w-fit flex justify-center bg-black">
              <QRCode size={122} style={{ height: 'auto', maxWidth: '100%', width: '100%' }} value={vibeCheck?.code?.toString() || ''} viewBox={`0 0 256 256`} />
            </div>
          </div>
        </TabPanel>
      </Tabs>
      <div className="w-full">{vibeCheck?.vibe_id && <VibeMatch vibeCheck={{ vibe_creator_id: vibeCheck?.vibe_creator_id, vibe_id: vibeCheck?.vibe_id }} />}</div>
    </div>
  );
};

const StartVibeCheck = () => {
  const [step, setStep] = useState<number>(0);
  const [selectedList, setSelectedList] = useState<IListResponse | undefined>();

  const user = getUserData();

  const { mutate: createVibeSession, isPending, data } = useCreateVibeSession();

  const handleNextStep = () => {
    createVibeSession(
      { vibe_creator_id: user?.user_id, list_id: selectedList?.list_id },
      {
        onSuccess: () => {
          setStep(step + 1);
        },
      }
    );
  };

  const STEPPER = [<SelectList handleNextStep={handleNextStep} selectedList={selectedList} setSelectedList={setSelectedList} isPending={isPending} />, <StartByCodeOrQR vibeCheck={data} />];

  return <div className="px-3 pb-4 max-w-[800px] m-auto">{STEPPER[step]}</div>;
};

export default StartVibeCheck;
