import { IGetCurrentUserRes, IGetUserRes, loginRes } from 'types/services';
import { IFilters } from 'types/common';
import axios from 'config/axios.';
import axiosWithoutParams from 'config/axiosWithoutParams';

const loginUser = async (payload: any): Promise<loginRes> => {
  const urlEncodedPayload = new URLSearchParams(payload).toString();
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: urlEncodedPayload,
  });

  if (!response.ok) {
    console.log(response);
    const error = await response.json();
    console.log(error);
    throw new Error(error.detail);
  }

  const data = await response.json();
  return data;
};

const signUpUser = async (payload: any) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    console.log(response);
    const error = await response.json();
    console.log(error);
    throw new Error(error.detail);
  }

  const data = await response.json();
  return data;
};

const getCurrentUser = async (): Promise<IGetCurrentUserRes> => {
  const response = await axios.post(`/get_current_user`);
  return response.data;
};
const forgotPassword = async (payload: { email: string }) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.detail || 'An error occured while sending the reset password link')
  }

  const data = await response.json();
  return data;
};
const resetPasswordVerification = async (token: string) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset-password-verification?token=${token}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail);
  }

  const data = await response.json();
  return data;
};

const updatePassword = async (payload: { token: string; new_password: string }) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail);
  }

  const data = await response.json();
  return data;
};

const verifyEmail = async (payload: { token: string }) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/verify-email?token=${payload?.token}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail);
  }

  const data = await response.json();
  return data;
};

const sendVerficationEmail = async (payload: { email: string }) => {
  const response = await axios.post(`/send-verification-email?email=${payload.email}`);
  return response.data;
};

const fetchUsers = async (filters: IFilters): Promise<IGetUserRes> => {
  let params: IFilters = {};
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      params[key as keyof IFilters] = value;
    }
  });
  const response = await axios.get('/get-users', {
    params,
  });
  return response.data;
};

const viewUser = async ({ userId }: { userId: number }) => {
  const response = await axiosWithoutParams.get('/view-user', {
    params: { user_id: userId },
  });
  return response.data;
};
export { loginUser, signUpUser, forgotPassword, resetPasswordVerification, updatePassword, getCurrentUser, sendVerficationEmail, verifyEmail, fetchUsers, viewUser };
