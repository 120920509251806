import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';

import { useGetCurrentUser } from 'services/user/user.query';

const AdminProtectedRoutes = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const [loading, setLoading] = useState(true);
  const { data, isLoading, isError } = useGetCurrentUser();

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
      navigate('/login');
    }
    if (isLoading) return;
    localStorage.setItem('user', JSON.stringify(data));
    if (data?.email !== process.env.REACT_APP_ADMIN_EMAIL) {
      navigate('/home');
    }
    setLoading(false);
  }, [data]);
  if (loading) {
    return (
      <div className="flex justify-center">
        <PuffLoader size={45} color="#00CED1" />
      </div>
    );
  }
  return <div>{children}</div>;
};

export default AdminProtectedRoutes;
