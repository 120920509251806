import TextField from 'components/TextField/TextField';
import { FaGoogle } from 'react-icons/fa';
import { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLoginUser } from 'services/user/user.query';
import { PuffLoader } from 'react-spinners';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
const Login: React.FC = () => {
  const [values, setValues] = useState({
    username_or_email: '',
    password: '',
  });
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { mutateAsync: loginUser, isPending } = useLoginUser();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    loginUser({
      username: values.username_or_email,
      password: values.password,
    })
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res));
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
      });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log('Login Success:', codeResponse);
      await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
          Authorization: `Bearer ${codeResponse.access_token}`,
          Accept: 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          loginUser({
            username: data.email,
            password: data.id,
          })
            .then((res) => {
              localStorage.setItem('user', JSON.stringify(res));
              navigate('/home');
            })
            .catch((err) => {
              console.log(err);
              setError(err.message || 'Error occurred while trying to login');
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <div className="max-w-[400px] mx-auto my-14">
      {isPending && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-gray-900 bg-opacity-50">
          <PuffLoader color="#fff" size={100} />
        </div>
      )}
      <form onSubmit={handleSubmit} className="flex flex-col gap-7 h-[100svh] mx-4">
        <div className="">
          <div className="flex justify-center mb-8">
            <h1 className="text-3xl font-bold text-[#052B33]">Log In</h1>
          </div>
          <div className="flex flex-col items-center gap-4">
            <TextField label="Email" placeholder="Enter email or username" onChange={setValues} field="username_or_email" required={true} />
            <TextField label="Password" placeholder="Enter your password" onChange={setValues} type="password" field="password" required={true} value={values.password} />
            <Link to="/forgot-password" className="w-full">
              <p className="w-full text-right text-sm">Forgot Password?</p>
            </Link>
            {error && <p className="text-red-500 text-[16px] font-semibold">{error}</p>}
            <button className="flex items-center justify-center px-3 py-3 bg-gray-100 rounded-full mt-4" type="button" onClick={() => handleGoogleLogin()}>
              <FaGoogle size={25} color="#052B33" />
            </button>
            <p className="text-[16px] font-semibold text-[#052B33] mt-2">Login with Google</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
          <button className="flex justify-center items-center w-full bg-blue-600 rounded-md text-center text-white py-3 font-semibold gap-3">Log in</button>
          <p className="text-[16px] text-[#637087] text-center">By continuing, you agree to our Terms of Service to learn how we collect, use and share your data</p>
          <Link to="/sign-up" className="text-[16px] text-[#052B33] font-semibold mt-4 pb-4">
            Don't have an account? Sign up
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
