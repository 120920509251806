import { NO_HOC_ROUTES, ONLY_LOGO_NAVBAR_ROUTES } from 'constants/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {!NO_HOC_ROUTES.some(value => pathname.includes(value)) ? (
        <div className="fixed h-[70px] w-full bg-white z-10 border-b border-[#E7E7E7] my-auto">
          <div className="flex justify-between items-center w-full lg:px-32 px-20 h-full ">
            <h1 className="text-[20px] leading-[23px] font-semibold">Just Vibes</h1>
            <div className="flex items-center justify-center lg:gap-10 gap-8">
              {!ONLY_LOGO_NAVBAR_ROUTES.some(value => pathname.includes(value)) && (
                <>
                  <Link to="/home" className={`text-[15px] leading-[22.4px] hover:text-[#195CE5] ${pathname.includes('/home') && 'text-[#195CE5]'}`}>
                    Home
                  </Link>
                  <Link to="/list" className={`text-[15px] leading-[22.4px] hover:text-[#195CE5] ${pathname.includes('/list') && 'text-[#195CE5]'}`}>
                    Lists
                  </Link>
                  <Link to="/add-interest" className={`text-[15px] leading-[22.4px] hover:text-[#195CE5] ${pathname.includes('/add-interest') && !pathname.includes('/profile') && 'text-[#195CE5]'}`}>
                    Interests
                  </Link>
                  <Link to="/profile" className={`text-[15px] leading-[22.4px] hover:text-[#195CE5] ${pathname.includes('/profile') && 'text-[#195CE5]'}`}>
                    Profile
                  </Link>
                </>
              )}
              <Link
                to="/login"
                onClick={() => {
                  localStorage.removeItem('access_token');
                  localStorage.removeItem('token_type');
                  localStorage.removeItem('user');
                }}
                className={`text-[15px] leading-[22.4px] hover:text-[#195CE5] ${pathname.includes('/login') && 'text-[#195CE5]'}`}
              >
                Logout
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Navbar;
