import { ProfileCard } from 'components/ui/ProfileCard';
import { imgLink } from 'constants/constants';
import { GoSignOut } from 'react-icons/go';
// import { getUserData } from 'utils/utils';
import { IoIosArrowForward } from 'react-icons/io';
// import speaker from "assets/SpeakerHigh.svg";
import { ReactComponent as Edit } from 'assets/PencilLine.svg';
// import { CiClock2 } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IUser } from 'types/common';
import { toast } from 'react-toastify';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser>();
  const handleSignOut = () => {
    toast.success('Signed out successfully');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    const userItem = localStorage.getItem('user');
    setUser(JSON.parse(userItem || '{}'));
  }, []);

  return (
    <div className="max-w-[450px] mx-auto">
      <div className="flex flex-col md:justify-start justify-between mx-4 h-[80svh]">
        <div className="flex flex-col gap-5">
          <ProfileCard
            name={user?.first_name}
            //greetings="How are you feeling today?"
            image={user?.image || imgLink}
            email={user?.email}
            type="profile"
            classForName=" text-[20px] leading-[22.5px] font-medium"
            location={user?.location}
          />
          <div className="flex flex-col bg-[#F7F6F9] md:bg-white  rounded-xl w-full px-4 py-1">
            <div className="flex justify-between items-center border-b border-gray-200 py-5 cursor-pointer md:hover:bg-slate-50 md:rounded-xl" onClick={() => handleNavigation('/profile/edit')}>
              <div className="flex justify-start items-center gap-3">
                <Edit height={24} width={24} />
                <p className="text-[#13141F] text-[18px] leading-[20.8px]">Edit Profile</p>
              </div>
              <div className="flex justify-end items-center">
                <IoIosArrowForward size={24} color="#13141F" />
              </div>
            </div>
            {/* <div className="flex justify-between items-center border-b border-gray-200 py-5">
                            <div className="flex justify-start items-center gap-3">
                                <img src={speaker} alt="speaker" height={24} width={24}/>
                                <p className="text-[#13141F] text-[18px] leading-[20.8px]">Notifications</p>
                            </div>
                            <div className="flex justify-end items-center">
                                <IoIosArrowForward size={24} color="#13141F"/>
                            </div>
                        </div>
                        <div className="flex justify-between items-center py-5">
                            <div className="flex justify-start items-center gap-3">
                                <CiClock2 size={24} color="#13141F"/>
                                <p className="text-[#13141F] text-[18px] leading-[20.8px]">Time zone</p>
                            </div>
                            <div className="flex justify-end items-center">
                                <IoIosArrowForward size={24} color="#13141F"/>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="pb-3 md:px-4 md:pt-3">
          <button className="flex justify-start items-center gap-3 cursor-pointer" onClick={() => handleSignOut()}>
            <GoSignOut size={24} color="#E64646" />
            <p className="text-[#E64646] text-[18px] leading-[20.8px]">Sign out of account</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
