import React, { useState } from 'react';
import { BiCopy } from 'react-icons/bi';

interface Props {
  text?: string;
}

const CopyComponent: React.FC<Props> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    // Check if copying to clipboard is supported
    if (!text) return;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1500); // Reset copy state after 1.5 seconds
        })
        .catch((error) => {
          console.error('Failed to copy:', error);
        });
    } else {
      // Fallback for browsers that don't support clipboard API
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px'; // Move it off-screen
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500); // Reset copy state after 1.5 seconds
    }
  };

  return (
    <div className="flex gap-1 items-center pl-4 cursor-pointer mt-6" onClick={handleCopy}>
      <BiCopy size={18} color="#808080" />
      <p className="text-[14px] text-[#808080] font-medium leading-[18.2px]">{isCopied ? 'Copied!' : 'Copy'}</p>
    </div>
  );
};

export default CopyComponent;
