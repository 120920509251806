import Axios from 'axios';
import { IUser } from 'types/common';
import { getToken } from 'utils/utils';
const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axios.interceptors.request.use((config) => {
  const userString = localStorage.getItem('user');
  const token = getToken();

  if (!!userString && userString !== 'undefined') {
    const user: IUser = JSON.parse(userString);
    config.params = {
      ...config.params,
      user_id: user.user_id,
    };
  }
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
  }
);

export default axios;
