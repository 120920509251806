import TextField from 'components/TextField/TextField';
import { FormEvent, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginUser, useSignUpUser } from 'services/user/user.query';
import { useGoogleLogin } from '@react-oauth/google';
import { PuffLoader } from 'react-spinners';

const Signup: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });
  const navigate = useNavigate();
  const { mutateAsync: signupUser } = useSignUpUser();
  const { mutateAsync: loginUser } = useLoginUser();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (values.password !== values.confirm_password) {
      setError('Passwords do not match’');
      setTimeout(() => {
        setError('');
      }, 5000);
      return;
    }
    signupUser({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      signup_type: '',
      image: '',
    })
      .then((res) => {
        navigate('/sign-up/confirmation');
      })
      .catch((err) => {
        console.log(err);
        setError(err.message || 'Error occured while signing up');
      });
  };

  const handleGoogleSignUp = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setLoading(true);
      console.log('Login Success:', codeResponse);
      await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
          Authorization: `Bearer ${codeResponse.access_token}`,
          Accept: 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          signupUser({
            first_name: data.given_name,
            last_name: data.family_name ?? 'a',
            email: data.email,
            password: data.id,
            signup_type: 'google',
            image: data.picture,
          })
            .then((res) => {
              console.log(res);
              loginUser({
                username: data.email,
                password: data.id,
              })
                .then((res) => {
                  console.log(res, '====> correct');
                  localStorage.setItem('user', JSON.stringify(res));
                  setLoading(false);
                  navigate('/home');
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                  setError(err.message);
                });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setError(err.message);
            });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
  });

  return (
    <>
      <div className="md:max-w-[800px] md:mx-auto my-14">
        {loading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-gray-900 bg-opacity-50">
            <PuffLoader color="#fff" size={100} />
          </div>
        )}
        <form onSubmit={handleSubmit} className="flex flex-col justify-between h-[100%] mx-4">
          <div className="">
            <div className="flex justify-center mb-8">
              <h1 className="text-3xl font-bold text-[#052B33]">Sign Up</h1>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 md:items-start md:gap-6 gap-4 items-center w-full">
              <div className="flex flex-col gap-4">
                <TextField label="First Name" placeholder="Enter first name" onChange={setValues} field="first_name" required={true} />
                <TextField label="Last Name" placeholder="Enter last name" onChange={setValues} field="last_name" required={true} />
                {/* <TextField label="Username" placeholder="username" onChange={setValues} field="username" required={false}/> */}
                <TextField label="Email" placeholder="Enter email" onChange={setValues} type="email" field="email" required={true} />
              </div>
              <div className="flex flex-col gap-4">
                <TextField label="Password" placeholder="Enter password" onChange={setValues} type="password" field="password" required={true} value={values.password} />
                <TextField label="Confirm Password" placeholder="Enter password again" onChange={setValues} type="password" field="confirm_password" required={true} value={values.confirm_password} />
              </div>
              <p className="text-red-500 text-[16px] font-semibold h-9 m-auto md:col-span-2">{error}</p>
            </div>
            <button className="flex items-center justify-center px-3 py-3 bg-gray-100 rounded-full md:mt-6 mt-4 mx-auto mb-6" type="button" onClick={() => handleGoogleSignUp()}>
              <FaGoogle size={25} color="#052B33" />
            </button>
            <p className="flex items-center justify-center text-[16px] font-semibold text-[#052B33] mb-7 mx-auto">Sign up with Google</p>
          </div>
          <div className="flex flex-col justify-center items-center gap-4">
            <button className="flex justify-center items-center w-full bg-blue-600 rounded-md text-center text-white py-3 max-w-[368px] font-semibold">Sign up</button>
            <p className="text-[16px] text-[#637087] text-center">By continuing, you agree to our Terms of Service to learn how we collect, use and share your data</p>
            <Link to="/login" className="text-[16px] text-[#052B33] font-semibold mt-4">
              Already have an account? Log in
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Signup;
