import axios from 'config/axios.';
import {
  ICreateVibeCheckPayload,
  ICreateVibeCheckResponse,
  IJoinVibePayload,
  IJoinVibeResponse,
  IGetVibeParticipantsPayload,
  IGetVibeParticipantsResponse,
  ISyncVibePayload,
  ISyncVibeResponse,
} from 'types/services';

const createVibeSession = async (payload: ICreateVibeCheckPayload): Promise<ICreateVibeCheckResponse> => {
  const response = await axios.post('create-vibe-check-session', payload);
  return response.data;
};

const joinVibeCheckSession = async (payload: IJoinVibePayload): Promise<IJoinVibeResponse> => {
  const response = await axios.post('join-vibe-check-session', payload);

  return response.data;
};

const getVibeParticipants = async (vibe_id: number): Promise<IGetVibeParticipantsResponse[]> => {
  const response = await axios.get(`/get-vibe-participants?vibe_id=${vibe_id}`);
  return response.data;
};

const syncVibe = async (payload: ISyncVibePayload): Promise<ISyncVibeResponse> => {
  const response = await axios.post('/sync-vibe', payload);
  return response.data;
};

export { createVibeSession, joinVibeCheckSession, getVibeParticipants, syncVibe };
