import { useMutation } from '@tanstack/react-query';
import { editProfile } from './profile.service';
import { toast } from 'react-toastify';

const useEditProfile = () => {
  return useMutation({
    mutationFn: editProfile,
    onSuccess(data, variables, context) {
      toast.success('Successfully edited profile');
    },
  });
};

export { useEditProfile };
